import { Redirect, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { Layout } from '../components/Layout';
import { Breadcrumbs } from '../components/global/Breadcrumbs';
import { AuthDetails } from '../components/login/AuthDetails';
import { AlumniToolkit } from '../components/alumniToolkits/AlumniToolkit';
import useTacfsService from '../utils/tacfs/useTacfsService';

import alumniToolkitsData from '../data/alumniTookKitsData.json';

export function AlumniToolkits() {
  const { toolkit } = useParams();
  const { load } = useTacfsService();

  const [toolkitItems, setToolkitItems] = useState(false);
  const [links, setLinks] = useState(false);
  const [canAccess, setCanAccess] = useState(true);
  const [breadcrumbsTitleOverride, setBreadcrumbsTitleOverride] = useState('');

  const {
    isLoading: completedDesignationsLoading,
    data: completedDesignations,
  } = useQuery(['completerKitInfo'], () => load('completerKitInfo'));

  useEffect(() => {
    if (!completedDesignationsLoading && completedDesignations) {
      let designationArr = [];
      completedDesignations?.forEach((designation) => {
        designationArr.push(designation.acad_plan);
      });
      if (!designationArr.includes(toolkit)) {
        setCanAccess(false);
      }
    }
    // @TODO: Check if needed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedDesignationsLoading, completedDesignations, canAccess]);

  useEffect(() => {
    if (canAccess && !completedDesignationsLoading) {
      setToolkitItems(alumniToolkitsData[toolkit.toLowerCase()]);
      setLinks(alumniToolkitsData[toolkit.toLowerCase()]['links']);
      setBreadcrumbsTitleOverride(`${toolkit}&reg; Toolkit`);
    }
  }, [toolkit, toolkitItems, links, canAccess, completedDesignationsLoading]);

  if (!canAccess) {
    return (
      <Redirect
        to={{ pathname: '/designations-certifications', state: null }}
      />
    );
  } else if (!completedDesignationsLoading) {
    return (
      <Layout>
        <Breadcrumbs overrideTitle={breadcrumbsTitleOverride} />
        <div className="container">
          <div className="intro-content-wrapper">
            <AuthDetails
              page="toolkits"
              toolkit={`${toolkit}<sup>&reg;</sup>`}
            />
          </div>
        </div>
        <div className="container toolkits">
          <div className="card-layout halves sm-gap">
            {links &&
              links.map((item, key) => {
                return <AlumniToolkit key={key} data={item} />;
              })}
          </div>
        </div>
      </Layout>
    );
  }
}
