import { Link } from 'react-router-dom';
import { RecommendedCourseDetails } from '../subcomponents/RecommendedCourseDetails';

export function RecommendedCourses({ flags }) {
  const ce128 = flags.ce128_elegibility;

  return (
    <div className="recommended-courses container">
      <div className="page-header">
        <h2>Recommended Courses</h2>
        <p>
          Learning is never final. As you look to expand your expertise, here’s
          a helpful path to potential success. Find a course that works for you!
        </p>
      </div>
      {/* "0002291" */}
      {ce128 === 'Y' && (
        <div className="card-layout thirds sm-gap recommended">
          <RecommendedCourseDetails
            title={'CE 128'}
            subhead={'Ethics in the Financial Services Profession'}
            description={
              ' This course reviews timely and relevant ethical issues in the financial services profession. This course is for ethics credit towards Professional Recertification credit only. The proctored version for state CE is currently being reviewed for state insurance CE approval.*State CE pending. PRP available for students.'
            }
            program={'ce'}
            course={'0003331'}
          />
          <RecommendedCourseDetails
            title={'CE 126'}
            subhead={"Ethics CE: CFP Board's Revised Code and Standards"}
            description={
              "This program fulfills the requirement for CFP Board approved Ethics CE. This program is designed to educate CFP® professionals on CFP Board's new Code of Ethics and Standards of Conduct, which is effective October 1, 2019."
            }
            program={'ce'}
            course={'0002291'}
          />
        </div>
      )}
      <div className="static-callouts">
        <h4>Discover learning opportunities at The College</h4>
        <div className="buttons">
          <Link to="/designations-certifications" className="btn">
            View Designations & Certifications
          </Link>
          <Link
            to={{
              pathname: `${process.env.REACT_APP_MARKETING_URL}learn/masters-degrees-graduate-programs/masters-degrees-graduate-programs`,
            }}
            target="_blank"
            className="btn"
          >
            View Master’s Degree Programs
          </Link>
          <Link
            to={{
              pathname: `${process.env.REACT_APP_MARKETING_URL}learn/certificate-programs/certificate-programs`,
            }}
            target="_blank"
            className="btn"
          >
            View Certificate Programs
          </Link>
        </div>
      </div>
      <div className="column-layout halves sm-gap mobile-vert-gap">
        <div className="col">
          <div className="promotional-callout dark">
            <div className="title2">Earn Valuable CE Credit</div>

            <p className="p3">
              Review state CE credit information by course, including state
              insurance CE. We do our best to keep this page current, but make
              sure to verify CE credits with your individual state.
            </p>

            <Link
              to={{
                pathname: `${process.env.REACT_APP_MARKETING_URL}learn/browse-all-programs`,
              }}
              target="_blank"
              className="btn tertiary"
            >
              Search CE By State
            </Link>
          </div>
        </div>

        <div className="col">
          <div className="promotional-callout light">
            <div className="title2">Ready for Your Next Achievement?</div>

            <p className="p3">
              We offer real-time, practical knowledge, curriculum delivery that
              meets the demands of your hectic schedule, and a proven approach
              to education in financial planning, retirement coaching,
              insurance, philanthropic giving, and more.
            </p>

            <Link
              to={{
                pathname: `${process.env.REACT_APP_MARKETING_URL}learn/continuing-education/continuing-education`,
              }}
              target="_blank"
              className="btn tertiary"
            >
              Degrees and Certifications
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
