import { OktaSSOLink } from '../../_okta/config/OktaSSO';
import SteppedProgressBar from '../subcomponents/SteppedProgressBar';
import dayjs from 'dayjs';

export const RecertIntro = ({ studentCE }) => {
  // Requested by Diane for WRP-1525
  const step3Msg = studentCE?.tac_ques_ce_ms
    ?.replace('CE Due by:', 'CE due by')
    .replace('on:', 'on');

  return (
    <div className="container intro-content-wrapper column-layout halves">
      <div className="col">
        <h2>Recertification</h2>
        <p className="page-intro-text">
          As an alum of a professional designation conferred by The College, you
          must satisfy the requirements for recertification. View the process
          below to remain in good standing.
        </p>
        <p>
          After completing the recertification process below, if you still need
          to fulfill your Ethics requirement due by{' '}
          {dayjs(studentCE.end_date).format('MMMM DD, YYYY')}, you can enroll in{' '}
          <OktaSSOLink program="ce" course="0003331">
            CE 128
          </OktaSSOLink>{' '}
          or{' '}
          <OktaSSOLink program="ce" course="0003331">
            CE 126
          </OktaSSOLink>
          . This course is free to all designees who recertify their
          designation(s) to satisfy the 1.00 hour of ethics continuing education
          requirement
        </p>
      </div>
      {studentCE && (
        <div className="col">
          <SteppedProgressBar
            step1={parseInt(studentCE?.tac_db_cmpl_step) >= 1}
            step2={parseInt(studentCE?.tac_db_cmpl_step) >= 2}
            step3={parseInt(studentCE?.tac_db_cmpl_step) === 3}
            step1Text=<div>
              Step 1:
              <br />
              Questionnaire
            </div>
            step2Text=<div>
              Step 2:
              <br />
              Payment
            </div>
            step3Text=<div>
              Step 3:
              <br />
              {step3Msg}
            </div>
          />
        </div>
      )}
    </div>
  );
};
