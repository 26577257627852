import Accordion from '../subcomponents/Accordion';
import { Link } from 'react-router-dom';
export default function FAQs() {
  return (
    <div className="faqs">
      <div className="container">
        <h2>Recertification FAQs</h2>

        <Accordion
          title=<h4> Who is required to recertify their designation?</h4>
          id="required_drawer"
        >
          <p className="p3">
            All designees who matriculated with The College and earned any
            credentials after June 1989, except for those currently in Emeritus
            status, must recertify. For those who earned designations prior to
            that date or who currently hold Emeritus status, participation is
            strongly encouraged for the good of the public and the financial
            services profession.
          </p>
        </Accordion>

        <Accordion
          title=<h4>Which designations are subject to recertification?</h4>
          id="subject_drawer"
        >
          <p className="p3">
            Once The College awards a designation, a designee has the right to
            use the designation mark provided all ongoing recertification
            requirements are met. Unlike academic degrees, designations can be
            removed for ethical violations or other forms of noncompliance.
          </p>
          <p className="p3">
            If you hold any of the following designations from The American
            College of Financial Services, you must participate in the
            Professional Recertification Program for your designation to remain
            active:
          </p>
          <ul className="bullets p3">
            <li>Chartered Advisor in Philanthropy&reg; (CAP&reg;)</li>
            <li>Chartered Financial Consultant&reg; (ChFC&reg;)</li>
            <li>Chartered Special Needs Consultant&reg; (ChSNC&reg;)</li>
            <li>Chartered Leadership Fellow&reg; (CLF&reg;)</li>
            <li>Chartered Life Underwriter&reg; (CLU&reg;)</li>
            <li>Financial Services Certified Professional&reg; (FSCP&reg;)</li>
            <li>Retirement Income Certified Professional&reg; (RICP&reg;)</li>
            <li>Wealth Management Certified Professional&reg; (WMCP&reg;)</li>
            <li>Chartered Advisor for Senior Living&reg; (CASL&reg;)</li>
          </ul>

          <p className="disclaimer">
            Participation in the Professional Recertification Program is
            generally not required for legacy marks no longer offered to new
            students by The College or for those conferred by other
            institutions, including Registered Employee Benefits Consultant&reg;
            (REBC&reg;), Registered Health Underwriter&reg; (RHU&reg;),
            Chartered Healthcare Consultant&reg; (ChHC&reg;), and Life
            Underwriter Training Council Fellow&reg; (LUTCF&reg;); however, the
            CASL&reg; designation remains subject to recertification.
          </p>
        </Accordion>

        <Accordion
          title=<h4>What is required for recertification?</h4>
          id="requiredforrecert_drawer"
        >
          <p className="p3">The College requires certain designees to:</p>
          <ul className="p3 bullets">
            <li>
              Recertify their knowledge with continuing education (CE) credit
              every two years
            </li>
            <li>
              Recommit to{' '}
              <Link
                to={{
                  pathname: `${process.env.REACT_APP_MARKETING_URL}about-the-college/the-college-at-a-glance/our-code-of-ethics`,
                }}
                target="_blank"
              >
                The College’s Code of Ethics
              </Link>
            </li>
            <li>Reconfirm client-facing status annually</li>
            <li>Update contact information annually</li>
            <li>
              Pay an annual fee to enable The College to enforce the
              professional standards of the program
            </li>
          </ul>
        </Accordion>

        <Accordion
          title=<h4>What is the continuing education requirement?</h4>
          id="ce_drawer"
        >
          <p className="p3">
            Designation holders who are considered client-facing are required to
            complete 30 credit hours of CE every two years, including one (1)
            hour of ethics CE. Designation holders who are considered
            non-client-facing are required to report one (1) hour of ethics CE
            every two years.
          </p>
          <p className="p3">
            CE credits earned through coursework at The College will be recorded
            automatically. CE credits taken outside of The College are
            self-reported.
          </p>
          <p className="p3">To earn CE credit, you must:</p>
          <p className="p3">
            A. Complete a pre-approved program that contains an examination or
            other procedure by which mastery of the program is demonstrated to
            the program’s sponsor. One (1) CE credit is earned for each hour of
            approved credit. Programs pre-approved for CE credits by the CFP
            Board of Standards, NASAA, State Insurance Commission, State Bar, or
            State Board of Accountancy are accepted by the Professional
            Recertification Program. These programs may be offered by any of the
            following:
          </p>
          <ul className="p3 bullets">
            <li>
              A nationally-recognized professional society or other nonprofit
              professional association
            </li>
            <li>A college or university</li>
            <li>
              Other continuing education providers approved by The College
            </li>
          </ul>
          <p className="p3">
            B. Pass an examination in the recertification period for:
          </p>
          <ul className="p3 bullets">
            <li>
              A course in The American College Huebner School or Graduate School
              program or another recognized professional designation program (30
              hours per course)
            </li>
            <li>A FINRA license (30 hours)</li>
            <li>The CFP&reg; certification (30 hours)</li>
            <li>
              A college or university course from which credit can be earned
              toward a degree (15 hours for each semester credit hour or its
              equivalent)
            </li>
          </ul>
          <p className="p3">
            C. Attend a live or virtual educational program or meeting that has
            at least one (1) credit hour (50 minutes of attendance equals one
            credit hour). These programs or meetings may be offered by any of
            the following groups:
          </p>
          <ul className="p3 bullets">
            <li>
              A nationally-recognized professional society or other nonprofit
              professional association, including its chapters
            </li>
            <li>A college or university</li>
            <li>
              The designee’s employer or any other organization that provides
              educational programs and meetings
            </li>
          </ul>
          <p className="p3">
            Credits approved for State Insurance CE, CFP&reg; certification,
            CPE, CLE, IAR CE, and Securities Professionals that are accrued in
            the Professional Recertification reporting cycle can be applied to
            Professional Recertification Program requirements. Credits earned
            outside the reporting cycle do not apply.
          </p>
          <p className="p3">
            The College offers an ethics course at no additional fee to
            participants. This course will satisfy the ethics CE requirement for
            the Professional Recertification Program. Participants may enroll in
            this course (CE128) through their College My Learning Hub account.
            The ethics credit may also be satisfied by taking approved courses
            from other providers.
          </p>
          <p className="p3">
            Should your client-facing status change mid-cycle, your CE
            requirements are still based on your status at the beginning of the
            current two-year cycle and will change at the beginning of the next
            cycle. The annual recertification fee will change to reflect current
            client-facing status at the next annual payment due date.
          </p>
        </Accordion>
        <Accordion
          title=<h4>
            Does The College offer CE programs for Professional Recertification?
          </h4>
          id="offerce_drawer"
        >
          <p className="p3">
            A variety of benefits and features support the Professional
            Recertification Program. The highlight of the program offering is
            access to Knowledge Hub+: an on-demand resource center of CFP and
            College CE credit-eligible content provided by thought leaders from
            The College and across the financial services industry.
          </p>
          <p className="p3">
            The College offers an ethics course at no additional fee to
            participants. This course will satisfy the ethics CE requirement for
            the Professional Recertification Program. Participants may enroll in
            this course (CE128) through their College My Learning Hub account.
            The ethics credit may also be satisfied by taking approved courses
            from other providers.
          </p>
        </Accordion>

        <Accordion
          title=<h4>How much is the recertification fee?</h4>
          id="recertfee_drawer"
        >
          <p className="p3">
            The annual fee for The College’s Professional Recertification
            Program is $190 (for client-facing advisors) or $105 (for
            non-client-facing professionals). The annual recertification fee
            will cover all designations earned from The College. The fees for
            the Professional Recertification Program are comparable with fees
            charged by other credentialing programs. As a nonprofit institution,
            we need to be able to fund both the increased educational resources
            provided to designees and the enhanced designee oversight and
            reporting we will now be doing on behalf of the public and the
            profession.
          </p>
        </Accordion>

        <Accordion
          title=<h4>
            How is my status of client facing or non-client facing determined?
          </h4>
          id="clientfacing_drawer"
        >
          <p className="p3">
            A client-facing individual is defined as someone who holds a
            Resident or Non-Resident State Life & Health License, and/or their
            Investment Company & Variable Products (Series 6), General
            Securities Representative (Series 7), Municipal Securities
            Representative (Series 52), Mutual Funds (Series 63), Uniform
            Investment Adviser Law (Series 65), or Uniform Combined State Law
            (Series 66) registrations from FINRA, and/or is a JD, CPA or
            CFA&reg;. Additionally, client-facing individuals work with clients
            and/or manage or supervise individuals who work with clients
            regarding sale, recommendation, or advice on insurance or investment
            products.
          </p>
          <p className="p3">
            Non-client facing individuals include those who do not work directly
            with clients regarding sale, recommendation, or advice on insurance
            or investment products. Those who work at a non-profit organization
            or retired individuals are also considered non-client-facing.
          </p>
          <p className="p3">
            The two levels of requirements are appropriate because client-facing
            individuals are using The College’s designations as a representation
            of their expertise when offering financial services to the public.
            They have a different role than non-client facing individuals and,
            therefore, are subject to different requirements for continuing
            education and oversight.
          </p>
        </Accordion>

        <Accordion
          title=<h4>How do I pay the recertification fee?</h4>
          id="howtopay_drawer"
        >
          <p className="p3">
            Paying your fee, reaffirming your commitment to ethical practice,
            and updating your contact information can all be done above and
            within your My Learning Hub account.
          </p>
        </Accordion>

        <Accordion
          title=<h4>
            Is the recertification fee considered a charitable donation to The
            College?
          </h4>
          id="donation_drawer"
        >
          <p className="p3">
            No. Like tuition and other College fees, recertification fees are
            not considered charitable donations. As a nonprofit 501(c)(3)
            educational institution, however, we rely on the generosity of our
            donors and will continue to do so. We encourage additional gifts to
            support The College. The generous charitable donations from our
            alumni and other donors are used to advance the mission of The
            College and support our academic programs, faculty research and
            scholarship programs. Visit the{' '}
            <Link
              to={{
                pathname: `${process.env.REACT_APP_MARKETING_URL}support-our-mission`,
              }}
              target="_blank"
            >
              Support Our Mission
            </Link>{' '}
            page to join our cause.
          </p>
        </Accordion>

        <Accordion
          title=<h4>When is the recertification fee due?</h4>
          id="feedue_drawer"
        >
          <p className="p3">
            The recertification fee is paid in advance during the last quarter
            of the recertification year, by December 31. Annual recertification
            fees cover a designee’s recertification commitment for the following
            calendar year. For example, the 2023 annual recertification fee
            would be due by December 31, 2022; the 2024 annual recertification
            fee would be due by December 31, 2023, and so on. A late fee will be
            assessed for fees paid after December 31.
          </p>
        </Accordion>

        <Accordion
          title=<h4>
            Why does The College require recertification for a designation but
            not a graduate degree?
          </h4>
          id="notgraduate_drawer"
        >
          <p className="p3">
            An academic degree (such as a PhD or Master’s degree) is a title
            signifying that the recipient has completed a course of study. By
            contrast, a designation signifies that the designee is certified to
            meet The College’s standards for providing financial services in a
            particular field. Every designee of The College has agreed to abide
            by a Code of Ethics that requires, among other responsibilities, the
            maintenance of a high level of professional competence by continuing
            studies throughout the designee’s career. Since 1989, The College
            has administered a formal Professional Recertification Program, and
            over time, its requirements have evolved to reflect the changing
            demands of the financial services profession.
          </p>
        </Accordion>

        <Accordion
          title=<h4>
            What happens if I don’t comply with the recertification
            requirements?
          </h4>
          id="comply_drawer"
        >
          <p className="p3">
            The College believes individuals who use our designations to
            represent their expertise to consumers have an obligation to the
            public to practice ethically and engage in ongoing professional
            education. Status under the Professional Recertification Program
            will be made available to the public on the{' '}
            <Link
              to={{ pathname: 'https://www.youradvisorguide.com' }}
              target="_blank"
            >
              Your Advisor Guide
            </Link>{' '}
            website, which will continue to serve as an index of a designee’s
            status with The College. Designation marks can be removed for
            ethical issues or failure to comply with other recertification
            requirements of The College.
          </p>
        </Accordion>

        <Accordion
          title=<h4>If I miss the window, can I still recertify?</h4>
          id="misswindow_drawer"
        >
          <p className="p3">
            The College reserves the right to remove or reinstate the right to
            use our designations for designees depending on their continued
            meeting of Professional Recertification Program guidelines, ethical
            violations, and other forms of noncompliance. For more detailed
            information, view our{' '}
            <Link
              to={{
                pathname: `${process.env.REACT_APP_MARKETING_URL}sites/default/files/reinstatementpolicy.pdf`,
              }}
              target="_blank"
            >
              official policy document
            </Link>
            .
          </p>
        </Accordion>
      </div>
    </div>
  );
}
