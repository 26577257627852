import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { OktaSSOLink } from '../_okta/config/OktaSSO';
import useTacfsService from '../utils/tacfs/useTacfsService';
import { Layout } from '../components/Layout';
import { Breadcrumbs } from '../components/global/Breadcrumbs';
import FAQs from '../components/recertification/FAQs';
import { ReceiptLink } from '../components/recertification/ReceiptLink';
import { RecertIntro } from '../components/recertification/RecertIntro';
import Timer from '../components/subcomponents/Timer';
import LocalTimer from '../components/subcomponents/LocalTimer';
import { openBase64NewTab } from '../utils/helper';

// Testing with 1617000
export function Recertification() {
  const { load } = useTacfsService();

  const [ethicsComplete, setEthicsComplete] = useState(false);
  const [payComplete, setPayComplete] = useState(false);
  const [ceRequired, setCeRequired] = useState(true);
  //  const [/*biannualDue,*/ setBiannualDue] = useState('');
  const [revoked, setRevoked] = useState(false);
  const [isPastPriorCEDate, setIsPastPriorCEDate] = useState(false);
  const [mostRecentLetter, setMostRecentLetter] = useState(false);
  const [recertPayment, setRecertPayment] = useState(false);
  const [letterDownload, setLetterDownload] = useState(false);

  const { isLoading: studentCELoading, data: studentCE } = useQuery(
    ['studentCE'],
    () => load('studentCE'),
  );
  const { isLoading: letterLoading, data: letter } = useQuery(
    [`${letterDownload.year}confirmationLetter`],
    () =>
      load('confirmationLetter', {
        beginDate: letterDownload.begin_date,
        yearDisplay: letterDownload.year,
      }),
    { enabled: letterDownload ? true : false },
  );
  const { isLoading: lettersLoading, data: letters } = useQuery(
    ['confirmationLetters'],
    () => load('confirmationLetters'),
    { enabled: studentCE?.tac_ques_ce_bt?.toUpperCase() === 'N' },
  );
  const { isLoading: purchaseHistoryLoading, data: purchaseHistory } = useQuery(
    ['purchaseHistory'],
    () => load('purchaseHistory'),
    { enabled: payComplete },
  );

  useEffect(() => {
    if (letterLoading === false && letter?.pdf_data && letterDownload) {
      openBase64NewTab(
        decodeURIComponent(letter.pdf_data),
        `${letterDownload.year}_confirmation_letter.pdf`,
      );
      setLetterDownload(false);
    }
  }, [letterLoading, letter, letterDownload]);

  useEffect(() => {
    if (!purchaseHistoryLoading && purchaseHistory && payComplete) {
      const recertReceipts = [];
      // Find the most recent recert receipt
      for (let i = 0; i < purchaseHistory.length; i++) {
        const receipt = purchaseHistory[i];
        // If the product title is a recert fee value, add it to our receipt array
        if (
          receipt.product_title.includes('Prof Recert-Client Facing') ||
          receipt.product_title.includes('Prof Recert-NonClient')
        ) {
          // It's a recert receipt. add it to our array!
          recertReceipts.push(receipt);
        }
      }
      // Sort the recert receipts ASC
      recertReceipts.sort((a, b) => {
        if (!dayjs(a.date_of_purchase).isValid()) return 1;
        if (!dayjs(b.date_of_purchase).isValid()) return -1;
        return dayjs(a.date_of_purchase).isBefore(dayjs(b.date_of_purchase))
          ? -1
          : 1;
      });
      // The latest recert receipt is the last element of the array.
      setRecertPayment(recertReceipts.slice(-1)[0]);
    }
  }, [
    setMostRecentLetter,
    purchaseHistoryLoading,
    purchaseHistory,
    payComplete,
  ]);

  useEffect(() => {
    if (!lettersLoading && letters) {
      // Find the most recent year in the array of letters
      const mostRecent = letters.reduce((acc, curr) => {
        console.log('Processing letters');
        if (curr.year > acc.year) {
          return curr;
        } else {
          return acc;
        }
      });
      console.log('Most recent is ', mostRecent);
      setMostRecentLetter(mostRecent);
    }
  }, [setMostRecentLetter, lettersLoading, letters]);
  useEffect(() => {
    if (studentCE) {
      //      setAnnualDue(dayjs(studentCE?.tac_ce_year).format('12/31/YY'));
      //      setBiannualDue(dayjs(studentCE?.end_date).format('MM/DD/YY'));

      const feeStatus = studentCE?.tac_recer_fee_stat?.trim();

      setEthicsComplete(
        studentCE?.tac_question_stat?.trim()?.toUpperCase() === 'C',
      );
      setPayComplete(
        !feeStatus ||
          feeStatus?.toUpperCase() === 'P' ||
          feeStatus?.toUpperCase() === 'N',
      );
      setCeRequired(studentCE?.tac_ce_req_flag?.trim()?.toUpperCase() === 'Y');
      //      setCeComplete(studentCE?.tac_ce_status?.trim() === 'Y');
      setRevoked(
        studentCE?.tac_revoked_flag?.trim()?.toUpperCase() === 'Y' ||
          (studentCE?.tac_compliant_stat?.trim()?.toUpperCase() === 'N' &&
            (feeStatus?.toUpperCase() === 'L' ||
              feeStatus?.toUpperCase() === 'R')),
      );

      if (studentCE?.tac_client_facing?.trim()?.toUpperCase() === 'Y') {
        //        setRecertFee('$125');
      } else {
        //        setRecertFee('$50');
      }
      if (dayjs(studentCE?.tac_priorce_end_dt) < dayjs()) {
        setIsPastPriorCEDate(true);
      }
    }
  }, [
    studentCELoading,
    studentCE /* setAnnualDue, setRecertFee, setCeComplete*/,
  ]);

  if (studentCELoading) {
    return (
      <Layout>
        <Breadcrumbs />
        <Timer />
      </Layout>
    );
  }

  return (
    <Layout>
      <Breadcrumbs />

      <div className="recertification container--full">
        <RecertIntro studentCE={studentCE} />
        {studentCE && Object.keys(studentCE).length > 0 && (
          <div className="recert-steps">
            <div className="container">
              <div className="recert-step flex column-layout">
                <div className="col col--two-thirds">
                  <h3>1. Complete Ethics Questionnaire</h3>
                  {ethicsComplete ? (
                    <p className="p3">
                      Thank You for completing the Ethics Questionaire - Based
                      on your answers your status is{' '}
                      {studentCE?.tac_ce_client_facn?.toUpperCase() === 'Y'
                        ? 'Client-Facing'
                        : 'Non Client-Facing'}
                      !
                    </p>
                  ) : (
                    <p className="p3">
                      Complete the questionnaire below to determine your status
                      as client facing or non-client facing. All questions must
                      be answered. The Office of the Registrar may contact you
                      and ask for additional information.
                    </p>
                  )}
                </div>
                <div className="col--third">
                  {studentCE?.tac_ques_ethics_bt?.toUpperCase() === 'N' ? (
                    <button className="btn disabled" disabled>
                      Completed
                    </button>
                  ) : (
                    <Link
                      to="recertification/ethics-questionnaire"
                      className="btn"
                    >
                      Ethics Questionnaire
                    </Link>
                  )}
                  <div
                    className={
                      studentCE?.tac_ques_ethics_bt === 'Y'
                        ? 'deadline due-date'
                        : 'completed due-date'
                    }
                  >
                    {studentCE?.tac_ques_ethics_ms}
                  </div>
                </div>
              </div>

              <div className="recert-step flex column-layout">
                <div className="col col--two-thirds">
                  <h3>2. Submit Payment</h3>
                  {payComplete ? (
                    <>
                      <p className="p3">Thank You for your Payment!</p>
                      <br className="desktop" />
                      <em>
                        Receipts may take a few minutes to show. If you don't
                        see it, please refresh this page or check back soon.
                      </em>
                    </>
                  ) : (
                    <p className="p3">
                      Please pay your annual recertification fee. This fee keeps
                      you in good standing with your designation(s) and affords
                      you numerous benefits as a designee in good standing!
                    </p>
                  )}
                </div>
                <div className="col--third">
                  {payComplete ? (
                    <button className="btn disabled" disabled>
                      Completed
                    </button>
                  ) : studentCE?.tac_ques_paym_bt?.toUpperCase() === 'Y' ? (
                    <OktaSSOLink classes="btn" rcrt="1" sameTab={true}>
                      Submit Payment
                    </OktaSSOLink>
                  ) : (
                    <button className="btn disabled" disabled>
                      Submit Payment
                    </button>
                  )}
                  <div
                    className={
                      !payComplete ? 'deadline due-date' : 'completed due-date'
                    }
                  >
                    {studentCE?.tac_ques_paym_ms}
                  </div>
                  {payComplete && recertPayment && (
                    <ReceiptLink purchase={recertPayment} />
                  )}
                </div>
              </div>

              <div className="recert-step flex column-layout">
                <div className="col col--two-thirds">
                  <h3>3. Verify Your Recertification CE</h3>
                  {ceRequired ? (
                    revoked ? (
                      //If not past date, show new verbiage.
                      !isPastPriorCEDate ? (
                        <p className="p3">
                          CE Due! To reinstate, CE requirements must be current.
                          If you fulfilled the CE requirements for the{' '}
                          {dayjs(studentCE?.tac_ce_begin_dt).format('YYYY')}/
                          {dayjs(studentCE?.tac_ce_end_dt).format('YYYY')}{' '}
                          period, please send to{' '}
                          <a href="mailto:Recertification@theamericancollege.edu">
                            Recertification@theamericancollege.edu
                          </a>{' '}
                          . If you did not satisfy CE requirements, you will
                          need to satisfy the requirements for{' '}
                          {dayjs(studentCE?.begin_date).format('YYYY')}/
                          {dayjs(studentCE?.end_date).format('YYYY')} to be
                          reinstated.
                        </p>
                      ) : //if IS past date, continue with ethics check
                      ethicsComplete ? (
                        <p className="p3">Completed, No CE Due at this time.</p>
                      ) : (
                        <p className="p3">CE Due Now!</p>
                      )
                    ) : (
                      <p className="p3">
                        You must report and verify continuing education (CE)
                        requirements for professional recertification. Report CE
                        by the due date!
                      </p>
                    )
                  ) : (
                    <p className="p3">
                      You are not required to report CE at this time.
                    </p>
                  )}
                </div>
                <div className="col--third">
                  {ceRequired && (
                    <>
                      <Link to="recertification/ce-reporting" className="btn">
                        Report PRP CE
                      </Link>
                      <div
                        className={
                          studentCE?.tac_ques_ce_bt === 'Y'
                            ? 'deadline due-date'
                            : 'completed due-date'
                        }
                      >
                        {studentCE?.tac_ques_ce_ms}
                      </div>
                      {studentCE?.tac_ques_ce_bt?.toUpperCase() === 'N' && (
                        <>
                          {mostRecentLetter && (
                            <div className="due-date">
                              <button
                                onClick={() => {
                                  if (letterLoading === false)
                                    setLetterDownload(mostRecentLetter);
                                }}
                                className="underline-link reflexblue"
                              >
                                View Confirmation Letter
                              </button>
                            </div>
                          )}
                          {letterLoading && (
                            <div className="due-date">
                              <LocalTimer />
                            </div>
                          )}
                          {!letterLoading && !lettersLoading && !letters && (
                            <>
                              <br />
                              <em>
                                Unable to load confirmation letter, please check
                                back at a later time.
                              </em>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Recert FAQs live in their own component. */}
        <FAQs />
      </div>
    </Layout>
  );
}
