import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import Divider from '@mui/material/Divider';
import { OktaSSOLink } from '../../_okta/config/OktaSSO';

import { getBadgeImage } from '../../utils/helper';

import { WarningIcon, InfoIcon } from '../subcomponents/Icon';
import Accordion from '../subcomponents/Accordion';
import {
  getRecertStatusRevised,
  getRecertDisplayRevised,
  compliantStatuses,
} from '../../pages/DesignationsCertifications';
import dayjs from 'dayjs';
import classOptions from '../../data/classOptions.json';
export function DesignationsModule({ completedDesignations, studentCE }) {
  const status = getRecertStatusRevised(studentCE);
  const recertCycle = !compliantStatuses.includes(status);
  // dates hardcoded for launch
  const currentYear = parseInt(dayjs().format('YYYY'));
  const previousYear = currentYear - 1;
  const currentMonth = parseInt(dayjs().format('MM'));

  let recertDate = !compliantStatuses.includes(status)
    ? `12/31/${previousYear}`
    : `12/31/${currentYear}`;
  if (currentMonth >= 9) {
    recertDate = `12/31/${currentYear}`;
  }

  const statusDisplay = getRecertDisplayRevised(studentCE);
  const designations = classOptions.designations;

  const ceReportRequired = studentCE?.tac_ce_req_flag?.trim() === 'Y';
  const questionnaireCompleted = studentCE?.tac_question_stat?.trim() === 'C';
  const paymementCompleted =
    studentCE?.tac_recer_fee_stat?.toUpperCase() === 'N' ||
    studentCE?.tac_recer_fee_stat?.toUpperCase() === 'P';
  const prpReported = studentCE?.tac_ques_ce_bt?.toUpperCase() === 'N';

  const ssoAccredible = () =>
    window.open(process.env.REACT_APP_ACCREDIBLE_SSO_URL, '_blank');
  return (
    <div className="card drop-box padding--sm w-full designations-module">
      <div className="flex card-header">
        <div className="title3">
          <Link to="/designations-certifications">
            My Designations & Certifications
          </Link>
        </div>
        <div className="status mobile">
          <p className="recert-status-wrapper p3">
            <span className="font-semibold">Status:</span> {statusDisplay}
          </p>

          {(status === 'pastdue' || status === 'immediateaction') && (
            <p className="p3 recert-notif">
              <span>
                <WarningIcon />
              </span>
              <span>
                Past Due - Your recertification was due by {recertDate}
              </span>
            </p>
          )}
          {status === 'firstyear' && <FirstYearNotice studentCE={studentCE} />}
        </div>
        {studentCE?.tac_db_ce_bt?.trim().toUpperCase() === 'Y' &&
          (!questionnaireCompleted || !paymementCompleted) && (
            <Link
              to="/designations-certifications/recertification/ce-reporting"
              className="btn"
            >
              Report PRP CE
            </Link>
          )}
      </div>

      <Divider className="top-divider" />

      <div className="content-wrapper">
        <div className="completion-img desktop">
          <div className="no-enrollment-img">
            <img alt="" src="/images/no-enrollment.svg" />
          </div>
        </div>
        <div className="status desktop">
          <p className="recert-status-wrapper p3">
            <span className="font-semibold">Status:</span> {statusDisplay}
          </p>
          {studentCE.tac_db_message && (
            <>
              {status === 'firstyear' && (
                <FirstYearNotice studentCE={studentCE} />
              )}
              {status !== 'firstyear' && (
                <p className="p3 recert-notif">
                  <span>
                    <WarningIcon />
                  </span>
                  <span>{studentCE.tac_db_message}</span>
                </p>
              )}
            </>
          )}
          {(studentCE.tac_db_paym_msg || studentCE.tac_db_ce_message) && (
            <ul className="bullets p3">
              {studentCE.tac_db_paym_msg && (
                <li>{studentCE.tac_db_paym_msg}</li>
              )}
              {studentCE.tac_db_ce_message && (
                <li>{studentCE.tac_db_ce_message}</li>
              )}
            </ul>
          )}

          {studentCE?.tac_db_rec_bt?.trim().toUpperCase() === 'Y' && (
            <>
              {ceReportRequired ? (
                <Link
                  to="/designations-certifications/recertification"
                  className="btn"
                >
                  {studentCE.tac_db_status?.toLowerCase() === 'recertified'
                    ? 'View'
                    : 'Recertify Now'}
                </Link>
              ) : questionnaireCompleted ? (
                <OktaSSOLink classes="btn" rcrt="1">
                  Recertify Now
                </OktaSSOLink>
              ) : (
                <Link
                  to="/designations-certifications/recertification/ethics-questionnaire"
                  className="btn"
                >
                  Recertify Now
                </Link>
              )}
            </>
          )}
          {studentCE.tac_db_status?.toLowerCase() === 'recertified' && (
            <p>
              Congratulations on meeting all the Recertification requirements!
              Click{' '}
              {
                <Link
                  to="/designations-certifications/recertification/ethics-questionnaire"
                  className="underline-text reflexBlue"
                >
                  here
                </Link>
              }{' '}
              to access detailed information about your recertification process.
            </p>
          )}
        </div>
        <div className="status mobile">
          {(studentCE.tac_db_paym_msg || studentCE.tac_db_ce_message) && (
            <ul className="bullets p3">
              {studentCE.tac_db_paym_msg && (
                <li>{studentCE.tac_db_paym_msg}</li>
              )}
              {studentCE.tac_db_ce_message && (
                <li>{studentCE.tac_db_ce_message}</li>
              )}
            </ul>
          )}

          {recertCycle && (
            <>
              {ceReportRequired ? (
                <Link
                  to="/designations-certifications/recertification"
                  className="btn"
                >
                  Recertify Now
                </Link>
              ) : questionnaireCompleted ? (
                <OktaSSOLink classes="btn" rcrt="1">
                  Recertify Now
                </OktaSSOLink>
              ) : (
                <Link
                  to="/designations-certifications/recertification/ethics-questionnaire"
                  className="btn"
                >
                  Recertify Now
                </Link>
              )}
            </>
          )}
          {studentCE.tac_db_status?.toLowerCase() === 'recertified' && (
            <p>
              Congratulations on meeting all the Recertification requirements!
              Click{' '}
              {
                <Link
                  to="/designations-certifications/recertification/ethics-questionnaire"
                  className="underline-text reflexBlue"
                >
                  here
                </Link>
              }{' '}
              to access detailed information about your recertification process.
            </p>
          )}
        </div>
        <div className="actionItems">
          <Divider orientation="vertical" flexItem className="desktop" />
          <div className="list">
            <div className="small-headline gray">Action Items</div>
            <ol>
              <li>
                Ethics Questionnaire
                <br />{' '}
                <span
                  className={`uppercase font-semibold ${
                    studentCE?.tac_ques_ethics_bt?.toUpperCase() === 'Y'
                      ? 'badStanding'
                      : 'goodStanding'
                  }`}
                >
                  {studentCE?.tac_ques_ethics_ms}
                </span>
              </li>
              <li>
                Submit Payment
                <br />{' '}
                <span
                  className={`uppercase font-semibold ${
                    paymementCompleted ? 'goodStanding' : 'badStanding'
                  }`}
                >
                  {studentCE?.tac_ques_paym_ms}
                </span>
              </li>
              <li>
                Report CE
                <br />{' '}
                <span
                  className={`uppercase font-semibold ${
                    prpReported ? 'goodStanding' : 'badStanding'
                  }`}
                >
                  {studentCE?.tac_ques_ce_ms}
                </span>
              </li>
            </ol>
            {studentCE?.tac_ques_ethics_bt?.toUpperCase() === 'Y' &&
              !questionnaireCompleted && (
                <Link
                  to="/designations-certifications/recertification/ethics-questionnaire"
                  className="btn"
                >
                  Step 1: Ethics Questionnaire
                </Link>
              )}
            {questionnaireCompleted && !paymementCompleted && (
              <OktaSSOLink classes="btn" rcrt="1" sameTab={true}>
                Step 2: Submit Payment
              </OktaSSOLink>
            )}
            {questionnaireCompleted && paymementCompleted && !prpReported && (
              <Link
                to="/designations-certifications/recertification/ce-reporting"
                className="btn"
              >
                Step 3: Report PRP CE
              </Link>
            )}
          </div>
        </div>
      </div>
      {completedDesignations.length > 0 && (
        <>
          <Divider className="bottom-divider" />

          <div className="badges desktop">
            <p className="title4">Utilize Your Digital Badges</p>

            <div className="designation-icons">
              {completedDesignations.forEach((designation) => {
                if (designation !== 'CFP') {
                  const badgeImage = getBadgeImage(designation);

                  return (
                    <div key={designation} className="designation-icon">
                      {badgeImage && (
                        <img
                          src={badgeImage}
                          alt={`${designation} Badge`}
                          className="badge-image"
                          onClick={ssoAccredible}
                        />
                      )}
                      {designations[designation.toLowerCase()] ? (
                        <p className="p3">
                          {parse(
                            designations[designation.toLowerCase()].shortName,
                          )}{' '}
                          -{parse(designations[designation.toLowerCase()].name)}
                        </p>
                      ) : (
                        <p className="p3">{designation}</p>
                      )}
                    </div>
                  );
                }
              })}
            </div>
          </div>

          <div className="badges mobile">
            <Accordion
              title=<p className="title4">Utilize Your Digital Badges</p>
              startOpen={true}
              id="digitalbadges"
            >
              <p className="p4 font-semibold">
                Click the &ndash; icon to hide your badges
              </p>
              <div className="designation-icons">
                {completedDesignations.forEach((designation) => {
                  if (designation !== 'CFP') {
                    const badgeImage = getBadgeImage(designation);

                    return (
                      <div key={designation} className="designation-icon">
                        {badgeImage && (
                          <img
                            src={badgeImage}
                            alt={`${designation} Badge`}
                            className="badge-image"
                          />
                        )}
                        {designations[designation.toLowerCase()] ? (
                          <p className="p3">
                            {parse(
                              designations[designation.toLowerCase()].shortName,
                            )}{' '}
                            -
                            {parse(
                              designations[designation.toLowerCase()].name,
                            )}
                          </p>
                        ) : (
                          <p className="p3">{designation}</p>
                        )}
                      </div>
                    );
                  }
                })}
              </div>
            </Accordion>
          </div>
        </>
      )}
    </div>
  );
}

export function DesignationsModuleNone() {
  return (
    <div className="card drop-box padding--sm w-full designations-module no-designations">
      <div className="flex card-header">
        <div className="title3">My Designations & Certifications</div>
      </div>

      <Divider className="top-divider" />

      <div className="content-wrapper">
        Your lifelong learning journey with The College is just beginning. If
        you’re in the middle of a program, keep going! If you want to start a
        new path, discover what learning at The College can mean for you. We’re
        here to help!
      </div>
    </div>
  );
}

function FirstYearNotice({ studentCE }) {
  return (
    <p className="p3 recert-notif recert-info">
      <span>
        <InfoIcon />
      </span>
      <span>
        Your first 2-year continuing education (CE) reporting cycle begins 1/1/
        {studentCE?.tac_first_ce_yr}. Your first payment of the annual fee for{' '}
        {dayjs(studentCE?.tac_first_fee_yr).add(1, 'year').format('YYYY')} will
        come due the last quarter of {studentCE?.tac_first_fee_yr}. Annual fees
        are paid in advance.
        <br /> <br />
        You will receive email reminders beginning 3 months prior to Fees and CE
        coming due. Please review the{' '}
        <Link
          to={{
            pathname: `${process.env.REACT_APP_MARKETING_URL}sites/default/files/2023-09/recertification-program-guidelines-2024.pdf`,
          }}
          target="_blank"
        >
          Professional Recertification Program Guidelines
        </Link>{' '}
        for full detail.
      </span>
    </p>
  );
}
