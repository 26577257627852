import { useState } from 'react';

import { useOktaAuth } from '@okta/okta-react';
import { Redirect, Link, useLocation } from 'react-router-dom';

import queryString from 'query-string';

import { Layout } from '../components/Layout';
import { BasicCreateAccountForm } from '../components/createAccount/BasicCreateAccountForm';

export function CreateAccount() {
  const { authState } = useOktaAuth();
  const { search, location } = useLocation();
  const query = queryString.parse(search);
  const [completed, setCompleted] = useState(false);

  if (completed) {
    return (
      <Layout>
        <div className="column-layout container">
          <div>
            <h2>Let's Get Started!</h2>
            <p>
              Thank you for starting the graduate application process. You will
              receive an email to finish setting up your account. After you have
              set up your account, you can complete the application by clicking
              the link below and logging into your learning hub.
            </p>
            <Link
              to={`/application-for-admission/${query.designation}/apply`}
              className="btn"
            >
              Complete My Graduate Application
            </Link>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <>
      {authState && authState.isAuthenticated ? (
        <Redirect to={{ pathname: '/dashboard', state: { from: location } }} />
      ) : (
        <Layout>
          <div className="container">
            <h2>Account Creation</h2>
            <h4>
              Welcome to The American College of Financial Services. Please
              follow the steps to setup your account.
            </h4>
            <p>
              Please ensure that your name matches your government-issued ID
              exactly. Consistency between these records is essential for exam
              scheduling.
            </p>
            <div className="form-layout">
              <BasicCreateAccountForm setCompleted={() => setCompleted(true)} />
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}
