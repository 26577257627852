import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, FieldArray, useFormikContext } from 'formik';
import * as Yup from 'yup';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import dayjs from 'dayjs';

import { useMutation, useQueryClient } from 'react-query';

import useTacfsService from '../../utils/tacfs/useTacfsService';

import ToolTip from '../subcomponents/ToolTip';
import Modal from '../subcomponents/Modal';
import {
  FormikText,
  FormikTextarea,
  FormikSelect,
  FormikCheckbox,
} from '../subcomponents/InputField';

import selectOptions from '../../data/selectOptions.json';

const nameOptions = selectOptions.nameDisplay;
const stateOptions = selectOptions.state;
const educationLevelOptions = selectOptions.educationLevel;
const monthOptions = selectOptions.month;
const monthPresentOptions = selectOptions.monthPresent;
const yearOptions = [{ label: 'Select', value: '' }];

// Start year dropdowns at 1974 per the old website
const thisYear = parseInt(dayjs().format('YYYY'));
for (let y = thisYear; y >= 1974; y--) {
  yearOptions.push({
    label: y + '',
    value: y + '',
  });
}

export function DesignationAppForm({ designation, profile }) {
  const { save } = useTacfsService();
  const [error, setError] = useState('');
  const [isSubmittingApp, setIsSubmittingApp] = useState(false);
  const [sent, setSent] = useState(false);
  // Get QueryClient from the context
  const queryClient = useQueryClient();
  //console.log(profile);

  const [step, setStep] = useState(1);
  const currentValidationSchema = ValidationSchema[step - 1];
  // @TODO: Can this be removed its never being set!
  const [displayStep1Errors] = useState(false);
  const [displayStep2Errors] = useState(false);
  const [displayStep3Errors] = useState(false);

  let address = {};
  if (profile.contact.address_type_primary?.trim() === 'R') {
    address = {
      address1: profile.contact.address1,
      address2: profile.contact.address2,
      city: profile.contact.city,
      state: profile.contact.state,
      zip: profile.contact.postal,
    };
  } else {
    address = {
      address1: profile.contact.address1_work,
      address2: profile.contact.address2_work,
      city: profile.contact.city_work,
      state: profile.contact.state_work,
      zip: profile.contact.postal_work,
    };
  }

  const initialPersonalInfo = {
    first_name: profile.initial.first_name?.trim(),
    middle_name: '',
    last_name: profile.initial.last_name?.trim(),
    display_name: 'FNLN',
    other_diploma_name: '',
    address1: address.address1?.trim(),
    address2: address.address2?.trim(),
    city: address.city?.trim(),
    state: address.state?.trim(),
    zip: address.zip?.trim(),
    phone:
      profile.contact.phone_type_primary?.trim() === 'R'
        ? formatPhone(profile.contact.phone?.trim())
        : formatPhone(profile.contact.phone_work?.trim()),
    email:
      profile.contact.email_type_primary?.trim() === 'R'
        ? profile.contact.email?.trim()
        : profile.contact.email_alternate?.trim(),
  };

  // Keep track of previously saved personal info
  // so that if user alters it and hits cancel, it can revert
  const [confirmedInfo, setConfirmedInfo] = useState(initialPersonalInfo);
  // Set to update initially if any profile info is missing
  const [updateInfo, setUpdateInfo] = useState(
    !initialPersonalInfo.address1 ||
      !initialPersonalInfo.city ||
      !initialPersonalInfo.state ||
      !initialPersonalInfo.zip ||
      !initialPersonalInfo.phone ||
      !initialPersonalInfo.email,
  );

  const { /*isLoading,*/ mutate: designationApp } = useMutation((values) =>
    save('designationApp', values),
  );

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 200);
  }, [step]);

  function handleNext(values, actions) {
    if (step === 3) {
      formSubmit(values);
    } else if (step === 1 && updateInfo) {
      setConfirmedInfo({
        first_name: values.first_name,
        middle_name: values.middle_name,
        last_name: values.last_name,
        display_name: values.display_name,
        other_diploma_name: values.other_diploma_name,
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: values.state,
        zip: values.zip,
        phone: values.phone,
        email: values.email,
      });

      setUpdateInfo(false);
    } else {
      setStep(step + 1);
      actions.setTouched({});
    }
  }

  function handleBack(setErrors) {
    setStep(step - 1);
    setErrors({});
  }

  const formSubmit = async (values) => {
    setIsSubmittingApp(true);
    let params = { studentID: profile.initial.empl_id };
    let data = { ...values };

    data.appid = 'NEXT';
    data.emplid = profile.initial.empl_id;
    data.phone = values.phone.replace(/\D/g, '');
    data.independent_advisor = values.independent_advisor ? 'Y' : 'N';
    data.unofficial_transcript = values.unofficial_transcript ? 'Y' : 'N';

    for (let i = 0; i < values.employment.length; i++) {
      data.employment[i].supervisor_number = values.employment[
        i
      ].supervisor_number.replace(/\D/g, '');
      data.employment[i].supervisor_fax = values.employment[
        i
      ].supervisor_fax.replace(/\D/g, '');

      // There is a typo in the required POST schema, but not changing the
      // object key everywhere in case at some point it is fixed
      data.employment[i].postition = values.employment[i].position;
      delete data.employment[i].position;

      // If user selects "present," send blank values
      if (data.employment[i].to_month === 'present') {
        data.employment[i].to_month = '';
        data.employment[i].to_year = '';
      }
    }

    data.cap_certify = values.cap_certify ? 'Y' : 'N';
    data.ethics = values.ethics ? 'Y' : 'N';
    data.recert_fee = values.recert_fee ? 'Y' : 'N';
    delete data.months_employment;

    params.data = data;

    designationApp(params, {
      onError: (res) => setError(res),
      onSuccess: (data) => {
        console.log(data);
        if (data.error === 'S') setSent(true);
        else setError(`There was an error with the form submission.`);
        // Invalidate every query with a key that starts with `todos`
        queryClient.invalidateQueries({ queryKey: ['designationAppStatus'] });
        setIsSubmittingApp(false);
      },
    });
  };

  // Show the confirmation message when submission is sent.
  if (sent)
    return <p className="title2 page-header">Thank you for your submission!</p>;

  return (
    <>
      {error && <p className="red-text">{error}</p>}
      <Formik
        initialValues={{
          ...initialPersonalInfo,
          acad_plan: designation,
          independent_advisor: false,
          education_level: '',
          education_level_other: '',
          educational_institution: '',
          unofficial_transcript: false,
          employment: [],
          months_employment: 0,
          defendant_lawsuit: '',
          defendant_lawsuit_explanation: '',
          affiliation_conduct: '',
          affiliation_explanation: '',
          felony: '',
          felony_explanation: '',
          cap_certify: false,
          ethics: false,
          recert_fee: false,
          signature: '',
          supervisor_name: '',
          supervisor_email: '',
        }}
        enableReinitialize={true}
        validationSchema={currentValidationSchema}
        onSubmit={handleNext}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setErrors,
        }) => (
          <form onSubmit={handleSubmit} id="designationAppForm">
            {step === 1 && (
              <div className="step-1">
                <div
                  className={
                    updateInfo ? 'display-info hidden' : 'display-info'
                  }
                >
                  <div className="page-header">
                    <p className="font-semibold">
                      Please carefully review your information below and update
                      or confirm to go to the next step in the application
                      process.
                    </p>
                    <p className="title3">Current Information</p>
                  </div>
                  <PageHeaderErrors displayErrors={displayStep1Errors} />

                  <div className="table">
                    <div className="row">
                      <div className="cell">Student ID:</div>
                      <div className="cell">{profile.initial.empl_id}</div>
                    </div>

                    <div className="row">
                      <div className="cell">Legal Name:</div>
                      <div className="cell tooltip">
                        <div className="whitespace-nowrap">
                          {values.first_name}
                          {values.middle_name ? ` ${values.middle_name} ` : ' '}
                          {values.last_name}
                        </div>
                        <ToolTip>
                          <p>
                            Please call the Registrar's office at{' '}
                            <a href="tel:6105261357">610-526-1357</a> if you
                            need to update your legal name.
                          </p>
                        </ToolTip>
                      </div>
                    </div>

                    <div className="row">
                      <div className="cell">Name Displayed on Diploma:</div>
                      <div className="cell">{diplomaDisplay(values)}</div>
                    </div>

                    <div className="row">
                      <div className="cell">Address:</div>
                      <div className="cell">
                        {values.address1} {values.address2}, {values.city},{' '}
                        {values.state} {values.zip}
                      </div>
                    </div>

                    <div className="row">
                      <div className="cell">Phone:</div>
                      <div className="cell">{values.phone}</div>
                    </div>

                    <div className="row">
                      <div className="cell">Email:</div>
                      <div className="cell">{values.email}</div>
                    </div>
                  </div>

                  <div className="buttons">
                    <button
                      type="button"
                      className="btn secondary large"
                      onClick={() => setUpdateInfo(true)}
                    >
                      Update
                    </button>
                    <button type="submit" className="btn">
                      Confirm
                    </button>
                  </div>
                  {/* End display info */}
                </div>

                <div
                  className={updateInfo ? 'update-info' : 'update-info hidden'}
                >
                  <p className="title3 page-header">Your Information</p>
                  <PageHeaderErrors displayErrors={displayStep1Errors} />

                  <p>
                    <span className="font-semibold">Student ID:</span>{' '}
                    {profile.initial.empl_id}
                  </p>
                  <div className="field-row">
                    <div className="field">
                      <FormikText
                        label="First Name"
                        type="text"
                        id="first_name"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                        disabled
                        formikRequired
                      />
                    </div>
                    <div className="field">
                      <FormikText
                        label="Middle Name/Initial"
                        type="text"
                        id="middle_name"
                        name="middle_name"
                        value={values.middle_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="field">
                      <FormikText
                        label="Last Name"
                        type="text"
                        id="last_name"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                        disabled
                        formikRequired
                      />
                    </div>
                  </div>

                  <div className="inline-field">
                    <FormikSelect
                      label="How do you want your name displayed on your diploma?"
                      id="display_name"
                      name="display_name"
                      value={values.display_name}
                      onChange={handleChange}
                      formikRequired
                    >
                      {nameOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </FormikSelect>
                  </div>

                  {values.display_name === 'NONE' && (
                    <div className="field">
                      <FormikText
                        label="Enter the name you would like on your diploma"
                        type="text"
                        id="other_diploma_name"
                        name="other_diploma_name"
                        value={values.other_diploma_name}
                        onChange={handleChange}
                        formikRequired
                        className="lg:w-1/2"
                      />
                    </div>
                  )}

                  <div className="field-row">
                    <div className="field">
                      <FormikText
                        label="Address"
                        type="text"
                        id="address1"
                        name="address1"
                        value={values.address1}
                        onChange={handleChange}
                        formikRequired
                      />
                    </div>

                    <div className="field">
                      <FormikText
                        label="Address 2"
                        type="text"
                        id="address2"
                        name="address2"
                        value={values.address2}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="field-row">
                    <div className="field">
                      <FormikText
                        label="City"
                        type="text"
                        id="city"
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        formikRequired
                      />
                    </div>

                    <div className="field">
                      <FormikSelect
                        label="State"
                        id="state"
                        name="state"
                        value={values.state}
                        onChange={handleChange}
                        formikRequired
                      >
                        {stateOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </FormikSelect>
                    </div>

                    <div className="field">
                      <FormikText
                        label="ZIP Code"
                        type="text"
                        id="zip"
                        name="zip"
                        value={values.zip}
                        onChange={handleChange}
                        formikRequired
                      />
                    </div>
                  </div>

                  <div className="field-row">
                    <div className="field">
                      <FormikText
                        label="Phone"
                        type="text"
                        id="phone"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        formikRequired
                      />
                    </div>

                    <div className="field">
                      <FormikText
                        label="Email"
                        type="email"
                        id="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        formikRequired
                      />
                    </div>
                  </div>

                  <p className="disclaimer">
                    <span className="font-semibold">NOTE:</span> You must submit
                    legal documentation if you entered a middle name/initial or
                    selected "Other" from the drop-down for how your want to
                    display your name on your diploma. Please send the
                    documentation to{' '}
                    <a href="mailto:Registrar@theamericancollege.edu">
                      Registrar@theamericancollege.edu
                    </a>{' '}
                    or fax the legal documentation to 610-526-1486.
                  </p>

                  <div className="buttons">
                    <button type="submit" className="btn">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn secondary large"
                      onClick={() => {
                        setUpdateInfo(false);
                        setFieldValue('middle_name', confirmedInfo.middle_name);
                        setFieldValue(
                          'display_name',
                          confirmedInfo.display_name,
                        );
                        setFieldValue(
                          'other_diploma_name',
                          confirmedInfo.other_diploma_name,
                        );
                        setFieldValue('address1', confirmedInfo.address1);
                        setFieldValue('address2', confirmedInfo.address2);
                        setFieldValue('city', confirmedInfo.city);
                        setFieldValue('state', confirmedInfo.state);
                        setFieldValue('zip', confirmedInfo.zip);
                        setFieldValue('phone', confirmedInfo.phone);
                        setFieldValue('email', confirmedInfo.email);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  {/* End update info */}
                </div>

                {/* End step 1 */}
              </div>
            )}

            {step === 2 && (
              <div className="step-2">
                <p className="page-header title3">
                  Education/Company Information
                </p>
                <PageHeaderErrors displayErrors={displayStep2Errors} />

                <ul className="checkboxes">
                  <li>
                    <FormikCheckbox
                      name="independent_advisor"
                      id="independent_advisor"
                    >
                      Click this box if you are an independent advisor or do not
                      have a direct supervisor for reviewing your designation
                      application
                    </FormikCheckbox>
                  </li>
                </ul>

                <div className="field-row ed-level">
                  <div className="field">
                    <FormikSelect
                      label="Highest Education Level"
                      id="education_level"
                      name="education_level"
                      value={values.education_level}
                      onChange={handleChange}
                      formikRequired
                    >
                      {educationLevelOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </FormikSelect>
                  </div>

                  <div className="field">
                    {values.education_level === '7' && (
                      <FormikText
                        label="Other"
                        type="text"
                        id="education_level_other"
                        name="education_level_other"
                        value={values.education_level_other}
                        onChange={handleChange}
                        formikRequired
                      />
                    )}
                  </div>
                </div>

                <div className="field">
                  <FormikText
                    label="Educational Institutions Attended"
                    type="text"
                    id="educational_institution"
                    name="educational_institution"
                    value={values.educational_institution}
                    onChange={handleChange}
                    formikRequired
                  />
                  <div>
                    Please list any higher education (bachelor, master,
                    doctorate) institutions you have attended. If you attended
                    multiple, please list them in chronological order and
                    include dates of attendance.
                  </div>
                </div>

                {designation !== 'fscp' && (
                  <div className="employment-record">
                    <p className="title4">
                      Employment Record <span className="req-star">*</span>
                    </p>
                    <p>
                      All designations require that applicants have at least
                      three years of qualifying business experience in five
                      years preceding application for the designation.
                    </p>
                    <ul className="checkboxes">
                      <li>
                        <FormikCheckbox
                          name="unofficial_transcript"
                          id="unofficial_transcript"
                        >
                          I will submit an unofficial transcript to count
                          towards one year of my business experience to{' '}
                          <a
                            href="mailto:Registrar@theamericancollege.edu"
                            className="underline-link reflexblue"
                          >
                            Registrar@theamericancollege.edu
                          </a>{' '}
                          or fax it to 610-526-1486.
                        </FormikCheckbox>
                      </li>
                    </ul>

                    <EmploymentRecord />
                  </div>
                )}

                <div className="buttons">
                  <button type="submit" className="btn">
                    Continue
                  </button>

                  <button
                    type="button"
                    className="btn secondary large"
                    onClick={() => handleBack(setErrors)}
                  >
                    Back
                  </button>
                </div>
                {/* End step 2 */}
              </div>
            )}

            {step === 3 && (
              <div className="step-3">
                <p className="title3 page-header">Personal Declarations</p>
                <PageHeaderErrors displayErrors={displayStep3Errors} />

                <div className="radio-question">
                  <label className="radios-label" id="lawsuit-radios">
                    1. Have you ever been requested to appear in court or before
                    a public official in connection with any conduct which was
                    under criticism?
                  </label>
                  <ul
                    className="radios"
                    role="group"
                    aria-labelledby="lawsuit-radios"
                  >
                    <li>
                      <Field
                        type="radio"
                        name="defendant_lawsuit"
                        value="Y"
                        id="lawsuit-yes"
                      />
                      <label htmlFor="lawsuit-yes">Yes</label>
                    </li>
                    <li>
                      <Field
                        type="radio"
                        name="defendant_lawsuit"
                        value="N"
                        id="lawsuit-no"
                      />
                      <label htmlFor="lawsuit-no">No</label>
                    </li>
                  </ul>
                </div>
                {values.defendant_lawsuit === 'Y' && (
                  <div className="field">
                    <FormikTextarea
                      label="Please explain fully the nature of the criticism and outcome of the action."
                      id="defendant_lawsuit_explanation"
                      name="defendant_lawsuit_explanation"
                      value={values.defendant_lawsuit_explanation}
                      onChange={handleChange}
                      formikRequired
                    />
                  </div>
                )}

                <div className="radio-question">
                  <label className="radios-label" id="affiliation-radios">
                    2. Have you ever had your membership in or affiliation with
                    any organization, association or entity suspended, revoked
                    or terminated as a result of your conduct?
                  </label>
                  <ul
                    className="radios"
                    role="group"
                    aria-labelledby="affiliation-radios"
                  >
                    <li>
                      <Field
                        type="radio"
                        name="affiliation_conduct"
                        value="Y"
                        id="affiliation-yes"
                      />
                      <label htmlFor="affiliation-yes">Yes</label>
                    </li>
                    <li>
                      <Field
                        type="radio"
                        name="affiliation_conduct"
                        value="N"
                        id="affiliation-no"
                      />
                      <label htmlFor="affiliation-no">No</label>
                    </li>
                  </ul>
                </div>
                {values.affiliation_conduct === 'Y' && (
                  <div className="field">
                    <FormikTextarea
                      label="Please identify the organization and explain fully the circumstances of the action taken by it."
                      id="affiliation_explanation"
                      name="affiliation_explanation"
                      value={values.affiliation_explanation}
                      onChange={handleChange}
                      formikRequired
                    />
                  </div>
                )}

                <div className="radio-question">
                  <label className="radios-label" id="felony-radios">
                    3. Have you ever been convicted of a felony?
                  </label>
                  <ul
                    className="radios"
                    role="group"
                    aria-labelledby="felony-radios"
                  >
                    <li>
                      <Field
                        type="radio"
                        name="felony"
                        value="Y"
                        id="felony-yes"
                      />
                      <label htmlFor="felony-yes">Yes</label>
                    </li>
                    <li>
                      <Field
                        type="radio"
                        name="felony"
                        value="N"
                        id="felony-no"
                      />
                      <label htmlFor="felony-no">No</label>
                    </li>
                  </ul>
                </div>
                {values.felony === 'Y' && (
                  <div className="field">
                    <FormikTextarea
                      label="Please explain."
                      id="felony_explanation"
                      name="felony_explanation"
                      value={values.felony_explanation}
                      onChange={handleChange}
                      formikRequired
                    />
                  </div>
                )}

                <hr />

                <ul className="checkboxes">
                  {designation === 'cap' && (
                    <li>
                      <FormikCheckbox name="cap_certify" id="cap_certify">
                        I certify that I have read and understand the{' '}
                        <Modal
                          title="Chartered Advisor in Philanthropy Ethic Requirements and Appendix"
                          linkClasses="underline-link reflexblue"
                          scrollable={true}
                        >
                          <div className="container">
                            <p className="title3">
                              Chartered Advisory in Philanthropy Ethics
                              Requirements
                            </p>
                            <p>
                              In applying for the Chartered Advisor in
                              Philanthropy designation you must subscribe to the
                              ethical standards as stated in The American
                              College Code of Ethics. Your assent is given by
                              signing The American College's Matriculation and
                              Application for Huebner School Designation Forms.
                            </p>

                            <p>
                              Award of the CAP designation also requires
                              adherence to additional unique ethical standards.
                            </p>

                            <ol>
                              <li>
                                You must subscribe to the provisions of The
                                Donor Bill of Rights originally promulgated by
                                The American Association of Fundraising Counsel
                                (AAFRC), the Association for Healthcare
                                Philanthropy (AHP), the Council for Advancement
                                and Support of Education (CASE), and the
                                Association of Fundraising Professionals (AFP).
                                Copies of these standards are provided to
                                applicants as part of the application process.
                              </li>

                              <li>
                                Meet professional codes, canons of ethics. or
                                standards of practice of licenses for
                                professional designations already earned.
                              </li>

                              <li>
                                Have adopted at least one of the following codes
                                of ethics or standards of practice applicable to
                                fundraisers and charitable planners:
                              </li>
                              <ul className="bullets">
                                <li>
                                  The Code of Ethical Principles and Standards
                                  of Professional Practice of the Association of
                                  Fundraising Professionals (AFP)
                                </li>

                                <li>
                                  The Model Standards of Practice for the
                                  Charitable Gift Planner adopted by the
                                  National Committee on Planned Giving (NCPG)
                                  and The American Council on Gift Annuities
                                  (ACGA)
                                </li>
                              </ul>
                            </ol>
                            <p>
                              I certify that I have read and understand the
                              above requirements. I further certify my
                              satisfaction of the requirements and future
                              adherence to their standards.
                            </p>
                          </div>
                        </Modal>{' '}
                        .
                      </FormikCheckbox>
                    </li>
                  )}
                  <li>
                    <FormikCheckbox name="ethics" id="ethics">
                      I have been furnished and have read the{' '}
                      <Modal
                        title="College's Code of Ethics"
                        linkClasses="underline-link reflexblue"
                        scrollable={true}
                      >
                        <div className="container">
                          <p className="title3">Code of Ethics</p>
                          <p>
                            To underscore the importance of maintaining ethical
                            standards in financial services, the Board of
                            Trustees of The American College of Financial
                            Services adopted a Code of Ethics in 1984. Embodied
                            in the Code are the designees’ Professional Pledge
                            and eight Canons.
                          </p>

                          <p className="title4">The Canons</p>

                          <ol className="upper-roman">
                            <li>
                              Conduct yourself at all times with honor and
                              dignity.
                            </li>
                            <li>
                              Avoid practices that would bring dishonor upon
                              your profession or The American College.
                            </li>
                            <li>
                              Publicize your achievements in ways that enhance
                              the integrity of your profession.
                            </li>
                            <li>
                              Continue your studies throughout your working life
                              so as to maintain a high level of professional
                              competence.
                            </li>
                            <li>
                              Do your utmost to attain a distinguished record of
                              professional service.
                            </li>
                            <li>
                              Support the established institutions and
                              organizations concerned with the integrity of your
                              profession.
                            </li>
                            <li>
                              Participate in building your profession by
                              encouraging and providing appropriate assistance
                              to qualified persons pursuing professional
                              studies.
                            </li>
                            <li>
                              Comply with all laws and regulations, particularly
                              as they relate to professional and business
                              activities.
                            </li>
                          </ol>

                          <p className="title4">The Professional Pledge</p>

                          <p className="italic">
                            "In all my professional relationships, I pledge
                            myself to the following rule of ethical conduct: I
                            shall, in light of all conditions surrounding those
                            I serve, which I shall make every conscientious
                            effort to ascertain and understand, render that
                            service which, in the same circumstances, I would
                            apply to myself."
                          </p>
                        </div>
                      </Modal>{' '}
                      and due process Procedures which can be invoked if I
                      violate the Code of Ethics. I agree to be bound by the
                      Code of Ethics and Procedures. I understand and agree that
                      the College has the right to deny the award of the
                      designation and/or to suspend the use of the designation
                      in the event that the Certification Committee determines I
                      have violated the Code of Ethics.
                    </FormikCheckbox>
                  </li>
                  <li>
                    <FormikCheckbox name="recert_fee" id="recert_fee">
                      I understand that upon award of my designation, I will be
                      obligated to comply with{' '}
                      <Modal
                        title="Professional Recertification"
                        linkClasses="underline-link reflexblue"
                        scrollable={true}
                      >
                        <div className="container">
                          <p className="title3">
                            Professional Recertification Program
                          </p>
                          <p>
                            Once The College awards a designation, that designee
                            has a “right to use” the designation mark, provided
                            all ongoing recertification requirements through the
                            Professional Recertification Program are met.
                          </p>

                          <p>
                            If you hold any of the following designations, you
                            must participate in the Professional Recertification
                            Program for your designation to remain active:
                          </p>
                          <ul className="bullets">
                            <li>CAP&reg;</li>
                            <li>CASL&reg;</li>
                            <li>ChFC&reg;</li>
                            <li>ChSNC&reg;</li>
                            <li>CLF&reg;</li>
                            <li>CLU&reg;</li>
                            <li>FSCP&reg;</li>
                            <li>RICP&reg;</li>
                            <li>WMCP&reg;</li>
                          </ul>

                          <p>
                            {/* TODO: insert new link once it's on marketing */}
                            <Link
                              to={{
                                pathname: `${process.env.REACT_APP_MARKETING_URL}professional-recertification`,
                              }}
                              target="_blank"
                            >
                              Learn more about the Professional Recertification
                              Program here.
                            </Link>
                          </p>
                        </div>
                      </Modal>{' '}
                      requirements, which includes the annual fee and continuing
                      education requirements of the designation.
                    </FormikCheckbox>
                  </li>
                </ul>

                <p>
                  I certify that all information on this application is correct.
                </p>
                <div className="field">
                  <FormikText
                    label="Your Signature"
                    type="text"
                    id="signature"
                    name="signature"
                    value={values.signature}
                    onChange={handleChange}
                    formikRequired
                    className="lg:w-1/2"
                  />
                </div>

                {!values.independent_advisor && (
                  <>
                    <hr />

                    <p>
                      Please provide us with your manager’s current name and
                      email address. Your manager will be sent a copy of this
                      application, so they can confirm that all provided
                      information is accurate, including the amount of time you
                      have been employed at your current organization.
                    </p>

                    <div className="field-row">
                      <div className="field">
                        <FormikText
                          label="Supervisor's Name"
                          type="text"
                          id="supervisor_name"
                          name="supervisor_name"
                          value={values.supervisor_name}
                          onChange={handleChange}
                          formikRequired
                        />
                      </div>
                      <div className="field">
                        <FormikText
                          label="Supervisor's Email"
                          type="email"
                          id="supervisor_email"
                          name="supervisor_email"
                          value={values.supervisor_email}
                          onChange={handleChange}
                          formikRequired
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="buttons">
                  {isSubmittingApp && (
                    <button
                      type="submit"
                      form="designationAppForm"
                      className="btn disabled"
                    >
                      Saving...
                    </button>
                  )}
                  {!isSubmittingApp && (
                    <button
                      type="submit"
                      form="designationAppForm"
                      className="btn"
                    >
                      Submit
                    </button>
                  )}

                  <button
                    type="button"
                    className="btn secondary large"
                    onClick={() => handleBack(setErrors)}
                  >
                    Back
                  </button>
                </div>
                {error && <div className="submit-error red-text">{error}</div>}
                {/* End step 3 */}
              </div>
            )}
          </form>
        )}
      </Formik>
    </>
  );
}

// This component is needed so we can keep track of the sum of the years in the date
// fields whenever a record is added, edited, or deleted
// Otherwise, when arrayhelpers removes a record from the FieldArray, it doesn't update the
// formik values until re-render, causing delete to not update the year total
const EmploymentRecord = () => {
  const formik = useFormikContext();
  const [updateCount, setUpdateCount] = useState(0);
  const [displayErrors, setDisplayErrors] = useState(false);

  useEffect(() => {
    formik.setFieldValue(
      'months_employment',
      calcMonthsEmployment(formik.values.employment),
    );
    // eslint-disable-next-line
  }, [updateCount]);

  // Keep track of current employment array when edit modal is opened
  // so that it can revert if user changes info and then hits cancel
  const [currentEmployment, setCurrentEmployment] = useState([]);
  // Keep track of if we are currently adding a new employment record
  // rather than editing an old one
  const [newEmployment, setNewEmployment] = useState(false);

  // Use to keep track of whether the edit and delete modals for each employment
  // record is visible
  // First set will set visibility of one modal by index,
  // second set will set entire array of modal visibilities
  const [employmentModals, setOneEmploymentModals, setEmploymentModals] =
    useModals(Array(formik.values.employment.length).fill(false));
  const [deleteModals, setOneDeleteModals, setDeleteModals] = useModals(
    Array(formik.values.employment.length).fill(false),
  );

  useEffect(() => {
    if (employmentModals.includes(true) || deleteModals.includes(true)) {
      document.querySelector('body').classList.remove('overflow-auto');
      document.querySelector('body').classList.add('overflow-hidden');
    } else {
      document.querySelector('body').classList.remove('overflow-hidden');
      document.querySelector('body').classList.add('overflow-auto');
    }
  }, [employmentModals, deleteModals]);

  const cancelEmploymentUpdate = (index, setFieldValue) => {
    setFieldValue('employment', currentEmployment);

    if (newEmployment) {
      deleteModals.pop();
      employmentModals.pop();
      setDeleteModals(deleteModals);
      setEmploymentModals(employmentModals);
    }

    setNewEmployment(false);
    setOneEmploymentModals(index, false);
  };

  const calcMonthsEmployment = (employment) => {
    return employment.reduce((sum, item) => {
      const start = dayjs(`${item.from_year}-${item.from_month}`);
      const end =
        item.to_month === 'present'
          ? dayjs()
          : dayjs(`${item.to_year}-${item.to_month}`);

      if (!start.isValid() || !end.isValid()) return sum + 0;
      return sum + end.diff(start, 'month');
    }, 0);
  };

  return (
    <FieldArray
      name="employment"
      render={(arrayHelpers) => (
        <>
          <div>
            <button
              type="button"
              className="btn"
              onClick={() => {
                setCurrentEmployment(formik.values.employment);
                setNewEmployment(true);
                arrayHelpers.push({
                  from_month: '',
                  from_year: '',
                  to_month: '',
                  to_year: '',
                  name: '',
                  position: '',
                  major_duties: '',
                  hours_of_work: '',
                  supervisor_name: '',
                  supervisor_address: '',
                  supervisor_address2: '',
                  supervisor_zip: '',
                  supervisor_city: '',
                  supervisor_state: '',
                  supervisor_number: '',
                  supervisor_fax: '',
                });
                // When you add a new employment record,
                // need to add an edit and delete modal for it
                employmentModals.push(true);
                deleteModals.push(false);
                setEmploymentModals(employmentModals);
                setDeleteModals(deleteModals);
              }}
            >
              Add Employment Information
            </button>
            {formik.errors['months_employment'] && (
              <p className="red-text years-error">
                {formik.errors['months_employment']}
              </p>
            )}
          </div>
          {formik.values.employment.map((data, i) => (
            <div className="card drop-box" key={i}>
              <div>
                <EmploymentModal
                  modals={employmentModals}
                  setOneModals={setOneEmploymentModals}
                  currentEmployment={currentEmployment}
                  setFieldValue={formik.setFieldValue}
                  index={i}
                >
                  <div
                    className="close"
                    onClick={() => {
                      cancelEmploymentUpdate(i, formik.setFieldValue);
                      setDisplayErrors(false);
                    }}
                  >
                    <span role="img" aria-label="close modal">
                      &times;
                    </span>
                  </div>
                  <div className="container">
                    <EmploymentInfo
                      handleChange={formik.handleChange}
                      arrayHelpers={arrayHelpers}
                      values={formik.values}
                      data={data}
                      index={i}
                      displayErrors={displayErrors}
                      errors={formik.errors}
                    />
                    <div className="buttons">
                      <button
                        type="button"
                        className="btn secondary large"
                        onClick={() => {
                          cancelEmploymentUpdate(i, formik.setFieldValue);
                          setDisplayErrors(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn"
                        onClick={() => {
                          if (
                            !formik.errors['employment'] ||
                            !formik.errors['employment'][i]
                          ) {
                            setNewEmployment(false);
                            setUpdateCount(updateCount + 1);
                            setOneEmploymentModals(i, false);
                            setDisplayErrors(false);
                          } else {
                            setDisplayErrors(true);
                            setTimeout(
                              () =>
                                document
                                  .querySelector('.modal.active .title3')
                                  .scrollIntoView({ behavior: 'smooth' }),
                              200,
                            );
                          }
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </EmploymentModal>
                <DeleteModal
                  modals={deleteModals}
                  setOneModals={setOneDeleteModals}
                  index={i}
                >
                  <div className="container">
                    <p>Are you sure you want to delete?</p>
                    <div className="buttons">
                      <button
                        type="button"
                        className="btn secondary large"
                        onClick={() => {
                          arrayHelpers.remove(i);
                          setUpdateCount(updateCount + 1);
                          employmentModals.splice(i, 1);
                          deleteModals.splice(i, 1);
                          setEmploymentModals(employmentModals);
                          setDeleteModals(deleteModals);
                          setOneDeleteModals(i, false);
                        }}
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        className="btn"
                        onClick={() => setOneDeleteModals(i, false)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </DeleteModal>
                <ToolTip
                  placement="bottom"
                  icon=<MoreHorizIcon fontSize="large" />
                >
                  <div className="tooltip-buttons">
                    <button
                      type="button"
                      className="underline-link"
                      onClick={() => {
                        setOneEmploymentModals(i, true);
                        setCurrentEmployment(formik.values.employment);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      className="underline-link"
                      onClick={() => setOneDeleteModals(i, true)}
                    >
                      Delete
                    </button>
                  </div>
                </ToolTip>
              </div>
              <EmploymentDisplay values={formik.values} index={i} />
            </div>
          ))}
        </>
      )}
    />
  );
};

function EmploymentInfo({
  index,
  values,
  handleChange,
  arrayHelpers,
  displayErrors,
  errors,
}) {
  const record = values.employment[index];

  return (
    <div>
      <div className="page-header">
        <p className="title3">Employment Record</p>
        <p>Please list employment information below.</p>

        {displayErrors &&
          errors['employment'] &&
          errors['employment'][index] && (
            <div className="red-text">
              Please fix errors & complete all required fields listed below.
            </div>
          )}
      </div>

      <p className="font-semibold">
        Date of Employment <span className="req-star">*</span>
      </p>
      <div className="field-row">
        <div>
          <p>From:</p>
          <div className="field-row">
            <div className="field">
              <FormikSelect
                label="Month"
                id={`employment[${index}].from_month`}
                name={`employment[${index}].from_month`}
                value={record.from_month}
                onChange={handleChange}
              >
                {monthOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </FormikSelect>
            </div>
            <div className="field">
              <FormikSelect
                label="Year"
                id={`employment[${index}].from_year`}
                name={`employment[${index}].from_year`}
                value={record.from_year}
                onChange={handleChange}
              >
                {yearOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </FormikSelect>
            </div>
          </div>
        </div>
        <div>
          <p>To:</p>
          <div className="field-row">
            <div className="field">
              <FormikSelect
                label="Month"
                id={`employment[${index}].to_month`}
                name={`employment[${index}].to_month`}
                value={record.to_month}
                onChange={handleChange}
              >
                {monthPresentOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </FormikSelect>
            </div>
            {record.to_month !== 'present' && (
              <div className="field">
                <FormikSelect
                  label="Year"
                  id={`employment[${index}].to_year`}
                  name={`employment[${index}].to_year`}
                  value={record.to_year}
                  onChange={handleChange}
                >
                  {yearOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormikSelect>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="field-row">
        <div className="field">
          <FormikText
            label="Name of Organization or Firm"
            type="text"
            id={`employment[${index}].name`}
            name={`employment[${index}].name`}
            value={record.name}
            onChange={handleChange}
            formikRequired
          />
        </div>
        <div className="field">
          <FormikText
            label="Position Held by Candidate"
            type="text"
            id={`employment[${index}].position`}
            name={`employment[${index}].position`}
            value={record.position}
            onChange={handleChange}
            formikRequired
          />
        </div>
      </div>
      <div className="field">
        <FormikTextarea
          label="Major Duties"
          id={`employment[${index}].major_duties`}
          name={`employment[${index}].major_duties`}
          value={record.major_duties}
          onChange={handleChange}
          formikRequired
        />
      </div>
      <div className="field">
        <FormikText
          label="Average No. of Hours per Week. This field only accepts integers. Please round to the nearest whole number and do not use special characters."
          type="text"
          id={`employment[${index}].hours_of_work`}
          name={`employment[${index}].hours_of_work`}
          value={record.hours_of_work}
          onChange={handleChange}
          formikRequired
          className="lg:w-1/4"
          inputmode="numeric"
        />
      </div>

      <hr />

      <p className="font-semibold">
        Immediate supervisor in organization or firm
      </p>
      <div className="field">
        <FormikText
          label="Full Name"
          type="text"
          id={`employment[${index}].supervisor_name`}
          name={`employment[${index}].supervisor_name`}
          value={record.supervisor_name}
          onChange={handleChange}
          formikRequired
          className="lg:w-1/2"
        />
      </div>
      <div className="field">
        <FormikText
          label="Address"
          type="text"
          id={`employment[${index}].supervisor_address`}
          name={`employment[${index}].supervisor_address`}
          value={record.supervisor_address}
          onChange={handleChange}
          formikRequired
        />
      </div>
      <div className="field">
        <FormikText
          label="Address 2"
          type="text"
          id={`employment[${index}].supervisor_address2`}
          name={`employment[${index}].supervisor_address2`}
          value={record.supervisor_address2}
          onChange={handleChange}
        />
      </div>
      <div className="field-row">
        <div className="field">
          <FormikText
            label="ZIP Code"
            type="text"
            id={`employment[${index}].supervisor_zip`}
            name={`employment[${index}].supervisor_zip`}
            value={record.supervisor_zip}
            onChange={handleChange}
            formikRequired
          />
        </div>
        <div className="field">
          <FormikText
            label="City"
            type="text"
            id={`employment[${index}].supervisor_city`}
            name={`employment[${index}].supervisor_city`}
            value={record.supervisor_city}
            onChange={handleChange}
            formikRequired
          />
        </div>
      </div>
      <div className="field">
        <FormikSelect
          label="State"
          type="text"
          id={`employment[${index}].supervisor_state`}
          name={`employment[${index}].supervisor_state`}
          value={record.supervisor_state}
          onChange={handleChange}
          formikRequired
          className="lg:w-1/2"
        >
          {stateOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </FormikSelect>
      </div>
      <div className="field-row">
        <div className="field">
          <FormikText
            label="Phone Number"
            type="text"
            id={`employment[${index}].supervisor_number`}
            name={`employment[${index}].supervisor_number`}
            value={record.supervisor_number}
            onChange={handleChange}
            formikRequired
          />
        </div>
        <div className="field">
          <FormikText
            label="Fax Number"
            type="text"
            id={`employment[${index}].supervisor_fax`}
            name={`employment[${index}].supervisor_fax`}
            value={record.supervisor_fax}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

function EmploymentDisplay({ values, index }) {
  const record = values.employment[index];

  return (
    <div className="padding--sm">
      <div className="employment-row">
        <p>
          <span className="font-semibold">Date:</span> {record.from_month}/
          {record.from_year} -{' '}
          {record.to_month === 'present' ? (
            <>Present</>
          ) : (
            <>
              {record.to_month}/{record.to_year}
            </>
          )}
        </p>
      </div>
      <div className="employment-row">
        <p>
          <span className="font-semibold">Name of organization or firm:</span>{' '}
          {record.name}
        </p>
      </div>
      <div className="employment-row">
        <p>
          <span className="font-semibold">Position held by candidate:</span>{' '}
          {record.position}
        </p>
      </div>
      <div className="employment-row">
        <p className="font-semibold mb-0">Duties:</p>
        <p>{record.major_duties}</p>
      </div>
      <div className="employment-row">
        <p>
          <span className="font-semibold">Average no. of hours per week:</span>{' '}
          {record.hours_of_work}
        </p>
      </div>
      <div className="employment-row">
        <p className="font-semibold mb-0">
          Immediate supervisor in organization or firm:
        </p>
        <div className="supervisor-info">
          <p>Name: {record.supervisor_name}</p>
          <p>
            Address: {record.supervisor_address} {record.supervisor_address2},{' '}
            {record.supervisor_city}, {record.supervisor_state}{' '}
            {record.supervisor_zip}
          </p>
          <p>Phone: {record.supervisor_number}</p>
          <p>Fax: {record.supervisor_fax}</p>
        </div>
      </div>
    </div>
  );
}

function EmploymentModal({ modals, index, children }) {
  return (
    <div className={modals[index] ? 'modal active' : 'modal'}>
      <div className="modalContent scrollable">{children}</div>
    </div>
  );
}

function DeleteModal({ modals, setOneModals, index, children }) {
  return (
    <div className={modals[index] ? 'modal active' : 'modal'}>
      <div className="modalContent scrollable">
        <div className="close" onClick={() => setOneModals(index, false)}>
          &times;
        </div>
        {children}
      </div>
    </div>
  );
}

const PageHeaderErrors = ({ displayErrors }) => {
  const { isSubmitting, submitCount, errors, isValid } = useFormikContext();

  useEffect(() => {
    if (!isValid && !isSubmitting) {
      setTimeout(() => window.scrollTo(0, 0), 500);
    }
  }, [submitCount, isSubmitting, isValid]);

  return (
    <div className="red-text errors">
      {Object.keys(errors).map((key) => {
        if (typeof errors[key] === 'string') {
          return <></>;
        }
        return <></>;
      })}
    </div>
  );
};

function useModals(initialValue) {
  const [values, setValues] = useState(initialValue);

  function handleOne(position, value) {
    const newValues = values.map((item, index) =>
      index === position ? value : item,
    );

    setValues(newValues);
  }

  return [values, handleOne, setValues];
}

function formatPhone(phone) {
  return phone ? phone.replace('/', '-') : '';
}

function diplomaDisplay(values) {
  switch (values.display_name) {
    case 'FNLN':
      return `${values.first_name} ${values.last_name}`;
    case 'FMIL':
      return `${values.first_name} ${
        values.middle_name ? values.middle_name[0] + '.' : ''
      } ${values.last_name}`;
    case 'NONE':
      return values.other_diploma_name;
    case 'FNMNLN':
    default:
      return `${values.first_name} ${values.middle_name} ${values.last_name}`;
  }
}

// RegEx for phone number validation
const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const zipRegExp = /^\d{5}$/;

const ValidationSchema = [
  Yup.object().shape({
    first_name: Yup.string()
      .max(50, 'First name is too long.')
      .required('First name is required.'),
    middle_name: Yup.string().max(50, 'Middle name is too long.'),
    last_name: Yup.string()
      .max(50, 'Last name is too long.')
      .required('Last name is required.'),
    display_name: Yup.string().required('Diploma display name is required.'),
    other_diploma_name: Yup.string()
      .max(50, 'Diploma display name is too long.')
      .when('display_name', {
        is: (display_name) => display_name === 'NONE',
        then: () => Yup.string().required('Diploma display name is required.'),
      }),
    address1: Yup.string().required('Address is required.'),
    city: Yup.string().required('City is required.'),
    state: Yup.string().required('State is required.'),
    zip: Yup.string()
      .matches(zipRegExp, 'ZIP Code is not valid')
      .required('ZIP Code is required.'),
    phone: Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Phone number is required.'),
    email: Yup.string().email('Invalid email').required('Email is required.'),
  }),

  Yup.object().shape({
    acad_plan: Yup.string(),
    independent_advisor: Yup.boolean(),
    education_level: Yup.string().required('Education level is required.'),
    education_level_other: Yup.string()
      .max(50, 'Education level is too long.')
      .when('education_level', {
        is: (education_level) => education_level === '7',
        then: () => Yup.string().required('Education level is required.'),
      }),
    educational_institution: Yup.string()
      .max(100, 'Educational institution is too long.')
      .required('Educational institution is required.'),
    unofficial_transcript: Yup.boolean(),
    months_employment: Yup.number()
      .when(['unofficial_transcript', 'acad_plan'], {
        is: (unofficial_transcript, acad_plan) =>
          acad_plan !== 'fscp' && unofficial_transcript,
        then: () => Yup.number().min(24, 'Two years of employment are needed.'),
      })
      .when(['unofficial_transcript', 'acad_plan'], {
        is: (unofficial_transcript, acad_plan) =>
          acad_plan !== 'fscp' && !unofficial_transcript,
        then: () =>
          Yup.number().min(36, 'Three years of employment are needed.'),
      }),
    employment: Yup.array().of(
      Yup.object().shape({
        from_month: Yup.string().required('From month is required.'),
        from_year: Yup.string().required('From year is required.'),
        to_month: Yup.string().required('To month is required.'),
        to_year: Yup.string().when('to_month', {
          is: (to_month) => to_month !== 'present',
          then: () => Yup.string().required('To year is required.'),
        }),
        name: Yup.string().required('Organization or firm is required.'),
        position: Yup.string().required('Position is required.'),
        major_duties: Yup.string().required('Major duties are required.'),
        hours_of_work: Yup.number()
          .integer('Hours of work must be an integer.')
          .max(100, 'Value must be less than 100')
          .required('Hours of work is required')
          .typeError('Hours of work must be a number.'),
        supervisor_name: Yup.string().required('Supervisor name is required.'),
        supervisor_address: Yup.string().required(
          'Supervisor address is required.',
        ),
        supervisor_zip: Yup.string()
          .matches(zipRegExp, 'ZIP Code is not valid')
          .required('Supervisor ZIP Code is required.'),
        supervisor_city: Yup.string().required('Supervisor city is required.'),
        supervisor_state: Yup.string().required(
          'Supervisor state is required.',
        ),
        supervisor_number: Yup.string()
          .matches(phoneRegExp, 'Phone number is not valid')
          .required('Supervisor phone is required.'),
        supervisor_fax: Yup.string().matches(phoneRegExp, 'Fax is not valid'),
      }),
    ),
  }),

  Yup.object().shape({
    acad_plan: Yup.string(),
    defendant_lawsuit: Yup.string().required(
      'You must indicate any court appearance.',
    ),
    defendant_lawsuit_explanation: Yup.string().when('defendant_lawsuit', {
      is: (defendant_lawsuit) => defendant_lawsuit === 'Y',
      then: () =>
        Yup.string().required('You must explain your court appearance.'),
    }),
    affiliation_conduct: Yup.string().required(
      'You must indicate any affiliation.',
    ),
    affiliation_explanation: Yup.string().when('affiliation_conduct', {
      is: (affiliation_conduct) => affiliation_conduct === 'Y',
      then: () => Yup.string().required('You must explain your affiliation.'),
    }),
    felony: Yup.string().required('You must indicate any felony.'),
    felony_explanation: Yup.string().when('felony', {
      is: (felony) => felony === 'Y',
      then: () => Yup.string().required('You must explain your felony.'),
    }),
    cap_certify: Yup.boolean().when('acad_plan', {
      is: (acad_plan) => acad_plan === 'cap',
      then: () =>
        Yup.boolean().oneOf(
          [true],
          'You must read the CAP Ethics Requirements.',
        ),
    }),
    ethics: Yup.boolean().oneOf([true], 'You must read the Code of Ethics.'),
    recert_fee: Yup.boolean().oneOf(
      [true],
      'You must read the Professional Recertification requirements.',
    ),
    signature: Yup.string().required('Signature is required.'),
    supervisor_name: Yup.string().when('independent_advisor', {
      is: (independent_advisor) => !independent_advisor,
      then: () => Yup.string().required('Supervisor name is required.'),
    }),
    supervisor_email: Yup.string().when('independent_advisor', {
      is: (independent_advisor) => !independent_advisor,
      then: () => Yup.string().required('Supervisor email is required.'),
    }),
  }),
];
