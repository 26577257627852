import { useState } from 'react';
import { useMutation } from 'react-query';
import dayjs from 'dayjs';

import useTacfsService from '../../utils/tacfs/useTacfsService';
import calculateProfileCompletion from '../../utils/calculateProfileCompletion';

import InputField from '../subcomponents/InputField';
import selectOptions from '../../data/selectOptions.json';
import mapping from '../../data/selectValuesMapping.json';
import { InfoIcon } from '../subcomponents/Icon';
import ToolTip from '../subcomponents/ToolTip';
import Divider from '@mui/material/Divider';
import ReadStorage from '../../components/storage/ReadStorage';

export function BasicInfoForm({
  userData,
  combinedProfile,
  setProfile,
  setPercentComplete,
}) {
  const { save } = useTacfsService();
  const [error, setError] = useState('');

  const { mutateAsync: optOutMutate } = useMutation((values) =>
    save('ssnOptOut', values),
  );

  const { isLoading: basicLoading, mutateAsync: basicMutate } = useMutation(
    (values) => save('studentContactall', values),
  );

  const [edit, setEdit] = useState(false);
  const genderOptions = selectOptions.gender;

  const [username] = useInput(userData.profile.login);
  const [birthdate, setBirthdate] = useInput(
    dayjs(combinedProfile.basic.birthdate).format('YYYY-MM-DD'),
  );
  const [gender, setGender] = useInput(combinedProfile.basic.sex);
  const [ssn, setSsn] = useInput(combinedProfile.basic.national_id_srh);
  const [optOut, setOptOut] = useState(
    combinedProfile.flags.ssn_opt_out?.trim() === 'Y',
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    const dobMin = dayjs().subtract(110, 'year').format('MM-DD-YYYY');
    const dobMax = dayjs().subtract(18, 'year').format('MM-DD-YYYY');
    const date1 = new Date(birthdate);
    const date2 = new Date(dobMin);
    const date3 = new Date(dobMax);

    if (date1.getTime() < date2.getTime()) {
      setError('This date is too old.');
      return;
    } else if (date1.getTime() > date3.getTime()) {
      setError('Must be at least 18 years old.');
      return;
    }

    const data = {
      date_of_birth: birthdate,
      gender: gender,
      military_status: combinedProfile.basic.military_flag,
      cit_country: 'USA',
      cit_status: combinedProfile.basic.tac_citizenship_cd,
      ethnicity: combinedProfile.basic.tac_ethnic_group_cd,
      hispanic: combinedProfile.basic.ethnic_group,
      country: 'USA',
    };

    let basicReqData;

    let ssnData, ssnResult, ssnReqData;

    // Only send ssn data if they have NOT opted out,
    // AND their ssn is NOT already in the system
    if (!optOut && !combinedProfile.basic.national_id_srh?.trim()) {
      // Regex for SSN format.
      const regex = /^(?!666|000|111|9\d{2})\d{3}?(?!00)\d{2}?(?!0{4})\d{4}$/;
      // we want to check the regex and end the function if
      // the regex does not match.
      if (!regex.test(ssn.replace(/\D/g, ''))) {
        setError('Invalid SSN format.');
        return;
      }
      ssnData = {
        ssn: ssn.replace(/\D/g, ''),
      };
      ssnResult = 'no call';
      // Only access ssnOptOut endpoint if the student is opting out
      // If the student has optOut selected but their SSN is already in
      // the system, we treat it as if they have opted in
    } else if (optOut && !combinedProfile.basic.national_id_srh?.trim()) {
      const optOutData = {
        studentID: userData.profile.studentID,
      };

      ssnResult = optOutMutate(optOutData, {
        onError: (res) => setError(res),
        onSuccess: (data) => {
          ssnReqData = data;
        },
      });
    } else {
      ssnResult = 'no call';
    }

    const basicData = {
      ...data,
      ...ssnData,
    };
    basicData.studentID = userData.profile.studentID;
    basicData.student_id = userData.profile.studentID;
    const basicResult = basicMutate(basicData, {
      onError: (res) => setError(res),
      onSuccess: (data) => {
        basicReqData = data;
      },
    });

    try {
      await Promise.all([basicResult, ssnResult]);
      if (
        (ssnResult !== 'no call' && (!ssnReqData || ssnReqData.code === 'E')) ||
        !basicReqData ||
        basicReqData.code === 'E'
      ) {
        setError('Error saving data.');
      } else {
        combinedProfile.basic.birthdate = birthdate;
        combinedProfile.basic.sex = gender;
        // If ssn was provided
        if (!optOut && !combinedProfile.basic.national_id_srh?.trim()) {
          combinedProfile.basic.national_id_srh = ssnData.ssn;
          combinedProfile.basic.national_id = '*****' + ssnData.ssn.slice(-4);
          // If opt out was provided
        } else if (optOut && !combinedProfile.basic.national_id_srh?.trim()) {
          combinedProfile.flags.ssn_opt_out = 'Y';
        }
        setProfile(combinedProfile);
        setPercentComplete(calculateProfileCompletion(combinedProfile));
        setEdit(false);
      }
    } catch {
      setError('Error saving data.');
    }
  };

  let fullName = combinedProfile.initial.first_name;
  if (combinedProfile.initial.middle_name)
    fullName += ' ' + combinedProfile.initial.middle_name;
  fullName += ' ' + combinedProfile.initial.last_name;
  if (combinedProfile.initial.name_suffix)
    fullName += ' ' + combinedProfile.initial.name_suffix;

  return (
    <div
      className={
        edit ? 'card drop-box padding--sm editing' : 'card drop-box padding--sm'
      }
    >
      <form onSubmit={handleSubmit}>
        <div className="flex card-header">
          <h4>Basic Information</h4>
          {edit ? (
            basicLoading ? (
              <input
                type="submit"
                value="Saving..."
                className="btn desktop-save disabled"
                disabled
              />
            ) : (
              <input type="submit" value="Save" className="btn desktop-save" />
            )
          ) : (
            <button
              onClick={() => setEdit(true)}
              className="btn secondary large desktop-edit"
            >
              Edit
            </button>
          )}
        </div>

        <Divider />

        <div
          className={edit ? 'table display-data hidden' : 'table display-data'}
        >
          <div className="row">
            <div className="cell">Name</div>
            <div className="cell">{fullName}</div>
          </div>
          <div className="row">
            <div className="cell">Student ID</div>
            <div className="cell">{userData.profile.studentID}</div>
          </div>
          <div className="row">
            <div className="cell">Username</div>
            <div className="cell">{username}</div>
          </div>
          <div className="row">
            <div className="cell">
              Date of Birth
              {(!birthdate || birthdate === 'Invalid Date') && (
                <>
                  <br />
                  <span className="missing-field">
                    Please add date of birth
                  </span>
                </>
              )}
            </div>
            <div className="cell">
              {birthdate && birthdate !== 'Invalid Date'
                ? dayjs(birthdate).format('MM/DD/YY')
                : 'Not Provided'}
            </div>
          </div>
          <div className="row">
            <div className="cell">
              Gender
              {!gender && (
                <>
                  <br />
                  <span className="missing-field">Please select gender</span>
                </>
              )}
            </div>
            <div className="cell">
              {mapping.gender[gender] || 'Not Provided'}
            </div>
          </div>
          <div className="row">
            <div className="cell">
              SSN
              {!ssn && !optOut && (
                <>
                  <br />
                  <span className="missing-field">Please add SSN</span>
                </>
              )}
            </div>
            <div className="cell">
              {ssn ? '***-**-****' : optOut ? 'Opted Out' : 'Not Provided'}
            </div>
          </div>
        </div>

        <div className={edit ? 'table info-form' : 'table info-form hidden'}>
          {error && (
            <div className="save-error row">
              <div className="cell"></div>
              <div className="cell">{error}</div>
            </div>
          )}
          <div className="row">
            <div className="cell">Name</div>
            <div className="cell">
              <InputField
                label="Name"
                readOnly={true}
                type="name"
                value={fullName}
                id="name-field"
                srOnly={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="cell">Student ID</div>
            <div className="cell">
              <InputField
                label="Student ID"
                readOnly={true}
                type="text"
                value={userData.profile.studentID}
                id="studentID-field"
                srOnly={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="cell">Username</div>
            <div className="cell">
              <InputField
                label="Username"
                id="username-field"
                name="username"
                type="userName"
                value={username}
                srOnly={true}
                readOnly={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="cell">
              Date of Birth <span className="req-star">*</span>
            </div>
            <div className="cell">
              {combinedProfile.basic.birthdate.trim() !== '' &&
              birthdate &&
              birthdate !== 'Invalid Date' ? (
                dayjs(birthdate).format('MM/DD/YY')
              ) : (
                <InputField
                  label="Date of Birth"
                  id="birthdate-field"
                  name="birthdate"
                  type="date"
                  value={birthdate}
                  onChange={setBirthdate}
                  srOnly={true}
                  required={true}
                />
              )}
            </div>
          </div>
          <div className="row inline-field">
            <div className="cell">
              <label htmlFor="gender-field">
                Gender <span className="req-star">*</span>
              </label>
            </div>
            <div className="cell">
              <select
                id="gender-field"
                name="gender"
                value={gender}
                onChange={setGender}
                required
              >
                {genderOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="cell">
              <span className="info">
                SSN
                {!optOut && <span className="req-star">*</span>}
                <ToolTip icon=<InfoIcon /> useDiv={true}>
                  <div className="text-left">
                    <h5 className="t-midnight">SSN Information</h5>
                    <p>
                      The law requires you to furnish a TIN/SSN so that we can
                      include it on an IRS information return
                    </p>
                  </div>
                </ToolTip>
              </span>
            </div>
            <div className="cell">
              <InputField
                label="SSN"
                readOnly={combinedProfile.basic.national_id_srh?.trim()}
                type="ssn"
                value={ssn}
                onChange={setSsn}
                id="ssn-field"
                name="ssn"
                srOnly={true}
                required={!optOut}
              />
            </div>
          </div>
          {!combinedProfile.basic.national_id_srh?.trim() && (
            <div className="row">
              <div className="cell"></div>
              <div className="cell">
                <ul className="checkboxes">
                  <li>
                    <input
                      type="checkbox"
                      id="ssn-opt-out"
                      name="ssn-opt-out"
                      value="Yes"
                      checked={optOut}
                      onChange={() => setOptOut(!optOut)}
                    />
                    <label htmlFor="ssn-opt-out">
                      I choose not to supply my Social Security Number. The
                      American College is required by the IRS to request the
                      Social Security Number for federal reporting purposes.
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        {edit ? (
          basicLoading ? (
            <input
              type="submit"
              value="Saving..."
              className="btn mobile-save disabled"
              disabled
            />
          ) : (
            <input type="submit" value="Save" className="btn mobile-save" />
          )
        ) : (
          <button
            onClick={() => setEdit(true)}
            className="btn secondary large mobile-edit"
          >
            Edit
          </button>
        )}
      </form>
    </div>
  );
}

export function DemographicInfoForm({
  userData,
  combinedProfile,
  setProfile,
  setPercentComplete,
}) {
  const { save } = useTacfsService();
  const [error, setError] = useState('');

  const { isLoading, mutate } = useMutation((values) =>
    save('studentBasic', values),
  );

  const [edit, setEdit] = useState(false);
  const [citizenship, setCitizenship] = useInput(
    combinedProfile.basic.tac_citizenship_cd,
  );
  const [race, setRace] = useInput(combinedProfile.basic.tac_ethnic_group_cd);
  const [ethnicity, setEthnicity] = useInput(
    combinedProfile.basic.ethnic_group,
  );
  const [military, setMilitary] = useInput(combinedProfile.basic.military_flag);

  const citizenshipOptions = selectOptions.citizenship;
  const raceOptions = selectOptions.race;
  const ethnicityOptions = selectOptions.ethnicity;
  const militaryOptions = selectOptions.military;

  if (ethnicity === 'N' && race === 'DNWTD') {
    setEthnicity('DNWTD');
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      studentID: userData.profile.studentID,
      birthdate: combinedProfile.basic.birthdate,
      gender: combinedProfile.basic.sex,
      military_status: military,
      cit_country: 'USA',
      cit_status: citizenship,
      ethnicity: ethnicity === 'DNWTD' ? 'DNWTD' : race,
      hispanic: ethnicity === 'DNWTD' ? 'N' : ethnicity,
      res_country: 'USA',
    };

    setError('');
    mutate(data, {
      onError: (res) => setError(res),
      onSuccess: (data) => {
        if (data) {
          if (data.code === 'E') {
            setError('Error saving data.');
          } else {
            combinedProfile.basic.military_flag = military;
            combinedProfile.basic.tac_citizenship_cd = citizenship;
            combinedProfile.basic.tac_ethnic_group_cd = race;
            combinedProfile.basic.ethnic_group = ethnicity;
            setProfile(combinedProfile);

            setPercentComplete(calculateProfileCompletion(combinedProfile));
            setEdit(false);
          }
        } else {
          setError('Error saving data.');
        }
      },
    });
  };

  return (
    <div
      className={
        edit ? 'card drop-box padding--sm editing' : 'card drop-box padding--sm'
      }
    >
      <form onSubmit={handleSubmit}>
        <div className="flex card-header">
          <h4>Demographic Information</h4>
          {edit ? (
            isLoading ? (
              <input
                type="submit"
                value="Saving..."
                className="btn desktop-save disabled"
                disabled
              />
            ) : (
              <input type="submit" value="Save" className="btn desktop-save" />
            )
          ) : (
            <button
              onClick={() => setEdit(true)}
              className="btn secondary large desktop-edit"
            >
              Edit
            </button>
          )}
        </div>

        <Divider />

        <div
          className={edit ? 'table display-data hidden' : 'table display-data'}
        >
          <div className="row">
            <div className="cell">
              Citizenship
              {!citizenship && (
                <>
                  <br />
                  <span className="missing-field">
                    Please select citizenship
                  </span>
                </>
              )}
            </div>
            <div className="cell">
              {mapping.citizenship[citizenship] || 'Not Provided'}
            </div>
          </div>
          <div className="row">
            <div className="cell">
              Race
              {!race && (
                <>
                  <br />
                  <span className="missing-field">Please select race</span>
                </>
              )}
            </div>
            <div className="cell">{mapping.race[race] || 'Not Provided'}</div>
          </div>
          <div className="row">
            <div className="cell">
              Ethnicity
              {!ethnicity && (
                <>
                  <br />
                  <span className="missing-field">Please select ethnicity</span>
                </>
              )}
            </div>
            <div className="cell">
              {mapping.ethnicity[ethnicity] || 'Not Provided'}
            </div>
          </div>
          <div className="row">
            <div className="cell">
              Miltary Status
              {!military && (
                <>
                  <br />
                  <span className="missing-field">
                    Please select military status
                  </span>
                </>
              )}
            </div>
            <div className="cell">
              {mapping.military[military] || 'Not Provided'}
            </div>
          </div>
        </div>

        <div className={edit ? 'table info-form' : 'table info-form hidden'}>
          {error && (
            <div className="save-error row">
              <div className="cell"></div>
              <div className="cell">Error saving data.</div>
            </div>
          )}
          <div className="row inline-field">
            <div className="cell">
              <label htmlFor="citizenship-field">
                Citizenship <span className="req-star">*</span>
              </label>
            </div>
            <div className="cell">
              <select
                id="citizenship-field"
                name="citizenship"
                value={citizenship}
                onChange={setCitizenship}
                required
              >
                {citizenshipOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row inline-field">
            <div className="cell">
              <label htmlFor="race-field">
                Race <span className="req-star">*</span>
              </label>
            </div>
            <div className="cell">
              <select
                id="race-field"
                name="race"
                value={race}
                onChange={setRace}
                required
              >
                {raceOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row inline-field">
            <div className="cell">
              <label htmlFor="ethnicity-field">Ethnicity</label>
            </div>
            <div className="cell">
              <select
                id="ethnicity-field"
                name="ethnicity"
                value={ethnicity}
                onChange={setEthnicity}
              >
                {ethnicityOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row inline-field">
            <div className="cell">
              <label htmlFor="military-field">
                Military Status <span className="req-star">*</span>
              </label>
            </div>
            <div className="cell">
              <select
                id="military-field"
                name="military"
                value={military}
                onChange={setMilitary}
                required
              >
                {militaryOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {edit ? (
          isLoading ? (
            <input
              type="submit"
              value="Saving..."
              className="btn mobile-save disabled"
              disabled
            />
          ) : (
            <input type="submit" value="Save" className="btn mobile-save" />
          )
        ) : (
          <button
            onClick={() => setEdit(true)}
            className="btn secondary large mobile-edit"
          >
            Edit
          </button>
        )}
      </form>
    </div>
  );
}

export function ContactInfoForm({
  userData,
  combinedProfile,
  setProfile,
  setPercentComplete,
}) {
  const { save } = useTacfsService();
  const [error, setError] = useState('');

  const { isLoading: resLoading /*, mutateAsync: resMutate */ } = useMutation(
    (values) => save('studentResidence', values),
  );

  const { isLoading: busLoading /*, mutateAsync: busMutate*/ } = useMutation(
    (values) => save('studentBusiness', values),
  );

  const { isLoading: contactLoading, mutateAsync: contactMutate } = useMutation(
    (values) => save('studentContactUpdate', values),
  );
  const { /*isLoading: isUpdatingPrimaryEmail,*/ mutate: updatePrimaryEmail } =
    useMutation((values) => save('updatePrimaryEmail', values));
  const [edit, setEdit] = useState(false);

  const [personalEmail, setPersonalEmail] = useInput(
    combinedProfile.contact.email,
  );
  const [personalPhone, setPersonalPhone] = useInput(
    formatPhone(combinedProfile.contact.phone),
  );
  const [homePhoneType, setHomePhoneType] = useInput(
    combinedProfile.contact.residence_phone_type,
  );
  const [homeAddress, setHomeAddress] = useInput(
    combinedProfile.contact.address1,
  );
  const [homeAddress2, setHomeAddress2] = useInput(
    combinedProfile.contact.address2,
  );
  const [homeCity, setHomeCity] = useInput(combinedProfile.contact.city);
  const [homeState, setHomeState] = useInput(combinedProfile.contact.state);
  const [homeZip, setHomeZip] = useInput(combinedProfile.contact.postal);

  const [businessEmail, setBusinessEmail] = useInput(
    combinedProfile.contact.email_alternate,
  );
  const [businessPhone, setBusinessPhone] = useInput(
    formatPhone(combinedProfile.contact.phone_work),
  );
  const [businessPhoneType, setBusinessPhoneType] = useInput(
    combinedProfile.contact.business_phone_type,
  );
  const [businessAddress, setBusinessAddress] = useInput(
    combinedProfile.contact.address1_work,
  );
  const [businessAddress2, setBusinessAddress2] = useInput(
    combinedProfile.contact.address2_work,
  );
  const [businessCity, setBusinessCity] = useInput(
    combinedProfile.contact.city_work,
  );
  const [businessState, setBusinessState] = useInput(
    combinedProfile.contact.state_work,
  );
  const [businessZip, setBusinessZip] = useInput(
    combinedProfile.contact.postal_work,
  );

  const [primaryEmail, setPrimaryEmail] = usePrimary(
    combinedProfile.contact.email_type_primary,
  );
  const [primaryPhone, setPrimaryPhone] = usePrimary(
    combinedProfile.contact.phone_type_primary,
  );
  const [primaryAddr, setPrimaryAddr] = usePrimary(
    combinedProfile.contact.address_type_primary,
  );

  const stateOptions = selectOptions.state;
  const phoneOptions = selectOptions.phoneType;

  // Index 0 is residential, index 1 is business
  function usePrimary(initialValue) {
    if (!initialValue) initialValue = '';
    const flags = [initialValue.trim() === 'R', initialValue.trim() === 'B'];

    const [values, setValues] = useState(flags);

    function handleChange(pos) {
      const otherPos = pos === 0 ? 1 : 0;
      let newValues = [];

      if (values[pos]) {
        newValues[pos] = false;
        newValues[otherPos] = true;
      } else {
        newValues[otherPos] = false;
        newValues[pos] = true;
      }

      setValues(newValues);
    }

    return [values, handleChange];
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const addrPrimary = primaryAddr[0] ? 'R' : 'B';
    const phonePrimary = primaryPhone[0] ? 'R' : 'B';
    const emailPrimary = primaryEmail[0] ? 'R' : 'B';

    const contactData = {};
    contactData.studentID = userData.profile.studentID;
    contactData.primary_address = addrPrimary;
    contactData.primary_phone = phonePrimary;
    contactData.primary_email = emailPrimary;

    const resData = {
      // studentID: userData.profile.studentID,
      email: personalEmail,
      address1: homeAddress,
      address2: homeAddress2,
      city: homeCity,
      state: homeState,
      postal: homeZip,
      country: 'USA',
      phone: personalPhone.replace(/\D/g, ''),
      phone_type: homePhoneType,
    };

    contactData.home = resData;

    // let resReqData;

    // setError('');
    // const resResult = resMutate(resData, {
    //   onError: (res) => setError(res),
    //   onSuccess: (data) => {
    //     resReqData = data;
    //   },
    // });

    const busData = {
      // studentID: userData.profile.studentID,
      email: businessEmail,
      address1: businessAddress,
      address2: businessAddress2,
      city: businessCity,
      state: businessState,
      postal: businessZip,
      country: 'USA',
      phone: businessPhone.replace(/\D/g, ''),
      phone_type: businessPhoneType,
    };

    contactData.business = busData;

    // let busReqData;

    // const busResult = busMutate(busData, {
    //   onError: (res) => setError(res),
    //   onSuccess: (data) => {
    //     busReqData = data;
    //   },
    // });

    let contactCallData;

    const contactResult = contactMutate(contactData, {
      onError: (res) => setError(res),
      onSuccess: (data) => {
        contactCallData = data;
      },
    });

    try {
      await Promise.all([contactResult]);

      if (!contactCallData || contactCallData.code === 'E') {
        setError('Error saving data.');
      } else {
        //Update Okta email
        const data = {
          newPrimaryEmail: emailPrimary === 'R' ? personalEmail : businessEmail,
        };
        // Check if this is a masquerading user.
        if (ReadStorage('masqueradingUser') !== undefined) {
          data.uid = ReadStorage('masqueradingUser');
        }

        updatePrimaryEmail(data, {
          onError: (res) => setError(res),
          onSuccess: (data) => {
            if (!data) {
              setError('Error updating primary email.');
            }
          },
        });

        combinedProfile.contact.email = personalEmail;
        combinedProfile.contact.address1 = homeAddress;
        combinedProfile.contact.address2 = homeAddress2;
        combinedProfile.contact.city = homeCity;
        combinedProfile.contact.state = homeState;
        combinedProfile.contact.postal = homeZip;
        combinedProfile.contact.phone = personalPhone;
        combinedProfile.contact.residence_phone_type = homePhoneType;
        combinedProfile.contact.email_type_primary = emailPrimary;
        combinedProfile.contact.phone_type_primary = phonePrimary;
        combinedProfile.contact.address_type_primary = addrPrimary;

        combinedProfile.contact.email_alternate = businessEmail;
        combinedProfile.contact.address1_work = businessAddress;
        combinedProfile.contact.address2_work = businessAddress2;
        combinedProfile.contact.city_work = businessCity;
        combinedProfile.contact.state_work = businessState;
        combinedProfile.contact.postal_work = businessZip;
        combinedProfile.contact.phone_work = businessPhone;
        combinedProfile.contact.business_phone_type = businessPhoneType;
        combinedProfile.contact.email_type_primary = emailPrimary;
        combinedProfile.contact.phone_type_primary = phonePrimary;
        combinedProfile.contact.address_type_primary = addrPrimary;

        setProfile(combinedProfile);
        setPercentComplete(calculateProfileCompletion(combinedProfile));
        setEdit(false);
      }
    } catch {
      setError('Error saving data.');
    }
  };

  return (
    <div
      className={
        edit ? 'card drop-box padding--sm editing' : 'card drop-box padding--sm'
      }
    >
      <form onSubmit={handleSubmit}>
        <div className="flex card-header">
          <h4>Contact Information</h4>
          {edit ? (
            busLoading || resLoading || contactLoading ? (
              <input
                type="submit"
                value="Saving..."
                className="btn desktop-save disabled"
                disabled
              />
            ) : (
              <input type="submit" value="Save" className="btn desktop-save" />
            )
          ) : (
            <button
              onClick={() => setEdit(true)}
              className="btn secondary large desktop-edit"
            >
              Edit
            </button>
          )}
        </div>

        <Divider />

        <div
          className={edit ? 'table display-data hidden' : 'table display-data'}
        >
          <div className="row">
            <div className="cell">
              {primaryEmail[0] && '*'}
              Personal Email
              {!personalEmail && (
                <>
                  <br />
                  <span className="missing-field">
                    Please add email address
                  </span>
                </>
              )}
            </div>
            <div className="cell">{personalEmail || 'Not Provided'}</div>
          </div>
          <div className="row">
            <div className="cell">
              {primaryPhone[0] && '*'}
              Personal Phone Number
              {!personalPhone && (
                <>
                  <br />
                  <span className="missing-field">Please add phone number</span>
                </>
              )}
            </div>
            <div className="cell">{personalPhone || 'Not Provided'}</div>
          </div>
          <div className="row">
            <div className="cell">
              {primaryAddr[0] && '*'}
              Home Address
              {(!homeAddress || !homeCity || !homeState || !homeZip) && (
                <>
                  <br />
                  <span className="missing-field">Please complete address</span>
                </>
              )}
            </div>
            <div className="cell">
              {homeAddress || homeAddress2 || homeCity || homeState || homeZip
                ? `${homeAddress}${
                    homeAddress2.length > 0 ? ' ' + homeAddress2 : ''
                  }, ${homeCity}, ${homeState} ${homeZip}`
                : 'Not Provided'}
            </div>
          </div>
          <div className="row">
            <div className="cell">
              {primaryEmail[1] && '*'}
              Business Email
              {!businessEmail && (
                <>
                  <br />
                  <span className="missing-field">
                    Please add email address
                  </span>
                </>
              )}
            </div>
            <div className="cell">{businessEmail || 'Not Provided'}</div>
          </div>
          <div className="row">
            <div className="cell">
              {primaryPhone[1] && '*'}
              Business Phone Number
              {!businessPhone && (
                <>
                  <br />
                  <span className="missing-field">Please add phone number</span>
                </>
              )}
            </div>
            <div className="cell">{businessPhone || 'Not Provided'}</div>
          </div>
          <div className="row">
            <div className="cell">
              {primaryAddr[1] && '*'}
              Business Address
              {(!businessAddress ||
                !businessCity ||
                !businessState ||
                !businessZip) && (
                <>
                  <br />
                  <span className="missing-field">Please complete address</span>
                </>
              )}
            </div>
            <div className="cell">
              {businessAddress ||
              businessAddress2 ||
              businessCity ||
              businessState ||
              businessZip
                ? `${businessAddress}${
                    businessAddress2.length > 0 ? ' ' + businessAddress2 : ''
                  }, ${businessCity}, ${businessState} ${businessZip}`
                : 'Not Provided'}
            </div>
          </div>
        </div>

        <div className={edit ? 'primary-footnote hidden' : 'primary-footnote'}>
          *Denotes primary communication
        </div>

        <div className={edit ? 'table info-form' : 'table info-form hidden'}>
          {error && (
            <div className="save-error row">
              <div className="cell"></div>
              <div className="cell">Error saving data.</div>
            </div>
          )}
          <div className="row">
            <div className="cell">
              Personal Email <span className="req-star">*</span>
            </div>
            <div className="cell">
              <InputField
                label="Personal Email"
                id="personalemail-field"
                name="personalEmail"
                type="email"
                value={personalEmail}
                onChange={setPersonalEmail}
                srOnly={true}
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="cell"></div>
            <div className="cell primary-checkbox">
              <ul className="checkboxes">
                <li>
                  <input
                    type="checkbox"
                    id="email-primary-res"
                    name="email-primary-res"
                    value="Yes"
                    checked={primaryEmail[0]}
                    onChange={() => setPrimaryEmail(0)}
                  />
                  <label htmlFor="email-primary-res">
                    Use as primary email address
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="cell">
              Personal Phone Number <span className="req-star">*</span>
            </div>
            <div className="cell">
              <InputField
                label="Personal Phone Number"
                id="personalphone-field"
                name="personalPhone"
                type="phone"
                value={personalPhone}
                onChange={setPersonalPhone}
                srOnly={true}
                required={true}
              />
            </div>
          </div>
          <div className="row inline-field">
            <div className="cell">
              <label htmlFor="homephonetype-field">
                Personal Phone Type <span className="req-star">*</span>
              </label>
            </div>
            <div className="cell">
              <select
                id="homephonetype-field"
                name="homephonetype"
                value={homePhoneType}
                onChange={setHomePhoneType}
                required
              >
                {phoneOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="cell"></div>
            <div className="cell primary-checkbox">
              <ul className="checkboxes">
                <li>
                  <input
                    type="checkbox"
                    id="phone-primary-res"
                    name="phone-primary-res"
                    value="Yes"
                    checked={primaryPhone[0]}
                    onChange={() => setPrimaryPhone(0)}
                  />
                  <label htmlFor="phone-primary-res">
                    Use as primary phone number
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="cell">
              Home Address <span className="req-star">*</span>
            </div>
            <div className="cell">
              <InputField
                label="Home Address"
                id="homeaddress-field"
                name="homeAddress"
                type="address"
                value={homeAddress}
                onChange={setHomeAddress}
                srOnly={true}
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="cell">Home Address 2</div>
            <div className="cell">
              <InputField
                label="Home Address 2"
                id="homeaddress2-field"
                name="homeAddress2"
                type="address"
                value={homeAddress2}
                onChange={setHomeAddress2}
                srOnly={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="cell">
              Home City <span className="req-star">*</span>
            </div>
            <div className="cell">
              <InputField
                label="Home City"
                id="homecity-field"
                name="homeCity"
                type="city"
                value={homeCity}
                onChange={setHomeCity}
                srOnly={true}
                required={true}
              />
            </div>
          </div>
          <div className="row inline-field">
            <div className="cell">
              <label htmlFor="homestate-field">
                Home State <span className="req-star">*</span>
              </label>
            </div>
            <div className="cell">
              <select
                id="homestate-field"
                name="homestate"
                value={homeState}
                onChange={setHomeState}
                required={true}
              >
                {stateOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="cell">
              Home Postal Code <span className="req-star">*</span>
            </div>
            <div className="cell">
              <InputField
                label="Home Postal Code"
                id="homezip-field"
                name="homeZip"
                type="zip"
                value={homeZip}
                onChange={setHomeZip}
                srOnly={true}
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="cell"></div>
            <div className="cell primary-checkbox">
              <ul className="checkboxes">
                <li>
                  <input
                    type="checkbox"
                    id="addr-primary-res"
                    name="addr-primary-res"
                    value="Yes"
                    checked={primaryAddr[0]}
                    onChange={() => setPrimaryAddr(0)}
                  />
                  <label htmlFor="addr-primary-res">
                    Use as primary address
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="cell">
              Business Email <span className="req-star">*</span>
            </div>
            <div className="cell">
              <InputField
                label="Business Email"
                id="businessemail-field"
                name="businessEmail"
                type="email"
                value={businessEmail}
                onChange={setBusinessEmail}
                srOnly={true}
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="cell"></div>
            <div className="cell primary-checkbox">
              <ul className="checkboxes">
                <li>
                  <input
                    type="checkbox"
                    id="email-primary-bus"
                    name="email-primary-bus"
                    value="Yes"
                    checked={primaryEmail[1]}
                    onChange={() => setPrimaryEmail(1)}
                  />
                  <label htmlFor="email-primary-bus">
                    Use as primary email address
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="cell">
              Business Phone Number <span className="req-star">*</span>
            </div>
            <div className="cell">
              <InputField
                label="Business Phone Number"
                id="businessphone-field"
                name="businessPhone"
                type="phone"
                value={businessPhone}
                onChange={setBusinessPhone}
                srOnly={true}
                required={true}
              />
            </div>
          </div>
          <div className="row inline-field">
            <div className="cell">
              <label htmlFor="busphonetype-field">
                Business Phone Type <span className="req-star">*</span>
              </label>
            </div>
            <div className="cell">
              <select
                id="busphonetype-field"
                name="busphonetype"
                value={businessPhoneType}
                onChange={setBusinessPhoneType}
                required
              >
                {phoneOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="cell"></div>
            <div className="cell primary-checkbox">
              <ul className="checkboxes">
                <li>
                  <input
                    type="checkbox"
                    id="phone-primary-bus"
                    name="phone-primary-bus"
                    value="Yes"
                    checked={primaryPhone[1]}
                    onChange={() => setPrimaryPhone(1)}
                  />
                  <label htmlFor="phone-primary-bus">
                    Use as primary phone number
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="cell">
              Business Address <span className="req-star">*</span>
            </div>
            <div className="cell">
              <InputField
                label="Business Address"
                id="businessaddress-field"
                name="businessAddress"
                type="address"
                value={businessAddress}
                onChange={setBusinessAddress}
                srOnly={true}
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="cell">Business Address 2</div>
            <div className="cell">
              <InputField
                label="Business Address 2"
                id="businessaddress2-field"
                name="businessAddress2"
                type="address"
                value={businessAddress2}
                onChange={setBusinessAddress2}
                srOnly={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="cell">
              Business City <span className="req-star">*</span>
            </div>
            <div className="cell">
              <InputField
                label="Business City"
                id="businesscity-field"
                name="businessCity"
                type="city"
                value={businessCity}
                onChange={setBusinessCity}
                srOnly={true}
                required={true}
              />
            </div>
          </div>
          <div className="row inline-field">
            <div className="cell">
              <label htmlFor="businessstate-field">
                Business State <span className="req-star">*</span>
              </label>
            </div>
            <div className="cell">
              <select
                id="businessstate-field"
                name="businessstate"
                value={businessState}
                onChange={setBusinessState}
                required={true}
              >
                {stateOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="cell">
              Business Postal Code <span className="req-star">*</span>
            </div>
            <div className="cell">
              <InputField
                label="Business Postal Code"
                id="businesszip-field"
                name="businessZip"
                type="zip"
                value={businessZip}
                onChange={setBusinessZip}
                srOnly={true}
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="cell"></div>
            <div className="cell primary-checkbox">
              <ul className="checkboxes">
                <li>
                  <input
                    type="checkbox"
                    id="addr-primary-bus"
                    name="addr-primary-bus"
                    value="Yes"
                    checked={primaryAddr[1]}
                    onChange={() => setPrimaryAddr(1)}
                  />
                  <label htmlFor="addr-primary-bus">
                    Use as primary address
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {edit ? (
          busLoading || resLoading || contactLoading ? (
            <input
              type="submit"
              value="Saving..."
              className="btn mobile-save disabled"
              disabled
            />
          ) : (
            <input type="submit" value="Save" className="btn mobile-save" />
          )
        ) : (
          <button
            onClick={() => setEdit(true)}
            className="btn secondary large mobile-edit"
          >
            Edit
          </button>
        )}
      </form>
    </div>
  );
}

function useInput(initialValue) {
  if (!initialValue) initialValue = '';
  const [value, setValue] = useState(initialValue.trim());

  function handleChange(e) {
    if (typeof e === 'string') {
      setValue(e);
    } else {
      setValue(e.target.value.trim());
    }
  }
  return [value, handleChange];
}

function formatPhone(phone) {
  return phone ? phone.replace('/', '-') : '';
}
