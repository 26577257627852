import { useState } from 'react';

import { useQuery, useMutation } from 'react-query';
import useTacfsService from '../../utils/tacfs/useTacfsService';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import FileUpload from '../subcomponents/FileUpload';
import { FormikSelect, FormikText } from '../subcomponents/InputField';
import Timer from '../subcomponents/Timer';

import selectOptions from '../../data/selectOptions.json';
import classOptions from '../../data/classOptions.json';
import mapping from '../../data/selectValuesMapping.json';

import { gaEvents } from '../../utils/gaEvents';
import CompanyAffiliationFormikField from '../subcomponents/companyFields/CompanyAffiliationFormikField';
import CompanyCustomFormikFields from '../subcomponents/companyFields/CompanyCustomFormikFields';
import {
  CustomizedCompanies as cc,
  OtherCompanyIds as ocId,
} from '../../data/Constants';

export function GraduateForm({ setStep, designation, authState }) {
  const [error, setError] = useState('');
  const [formSubmitting, isFormSubmitting] = useState(false);
  const stateOptions = selectOptions.state;
  const phoneOptions = selectOptions.phoneType;
  const cohortDates = selectOptions.graduateCohortDates;
  const concentration = selectOptions.graduateConcentration;
  const designations = classOptions.designations;
  const isReturning = authState != null && authState.isAuthenticated;
  const { load, save } = useTacfsService();
  const { isLoading: submitting, mutate: submitApplication } = useMutation(
    (values) => save('gradApplication', values),
  );
  const { companiesLoading, data: companies } = useQuery(['companies'], () =>
    load('companies'),
  );
  const { isLoading, data: student } = useQuery(
    ['studentInitial'],
    () => load('studentInitial'),
    {
      enabled: isReturning,
    },
  );
  const { isLoading: contactLoading, data: contact } = useQuery(
    ['studentContact'],
    () => load('studentContact'),
    {
      enabled: isReturning,
    },
  );
  const { isLoading: companyLoading, data: company } = useQuery(
    ['studentCompany'],
    () => load('studentCompany'),
    {
      enabled: isReturning,
    },
  );
  const { mutate: companyUpdate } = useMutation((values) =>
    save('studentCompany', values),
  );
  const { mutate: residenceUpdate } = useMutation((values) =>
    save('studentResidence', values),
  );

  const formSubmit = async (values) => {
    if (!submitting) {
      setError('');
      isFormSubmitting(true);
      let rawDesignation;
      // For MSFP the designation is pulled
      // from the form input.
      if (designation === 'msfp') {
        rawDesignation = values.concentration;
      } else {
        rawDesignation = designation;
      }
      // Add the designation specific data.
      if (designation.toLowerCase() === 'msfp') {
        const rawConcentration = concentration.filter((item) => {
          return item.value === values.concentration;
        });
        values.concentrationLabel = rawConcentration[0].label;
      } else if (designation.toLowerCase() === 'msm') {
        const rawCohortDates = cohortDates.filter((item) => {
          return item.value === values.cohort;
        });
        values.cohortLabel = rawCohortDates[0].label;
      }
      values.formPlan = designation;
      values.plan = rawDesignation;
      values.career = designations[rawDesignation].career;
      values.program = designations[rawDesignation].program;
      // We need to add the company fields to the endpoint bc
      // they are needed in the endpoint call.
      values.affiliation_id = isReturning ? company?.agent_id : values;
      values.role = isReturning ? company?.agent_role : '';
      values.lsp_cd = isReturning ? company?.lsp_cd?.trim() : '';
      //      values.ntid = isReturning ? company?.nt_id?.trim() : '';
      // Returning students we need
      // to update the residence and
      // company information.

      values.company = values?.company2 ? values.company2 : values.company1;
      values.companyName = values?.userCompanyName
        ? values.userCompanyName
        : values.companyName;
      //       console.log(values);
      //        isFormSubmitting(false);
      //       return;
      if (isReturning) {
        companyUpdate({
          company_id: values.company,
          company_name: company?.company_name.substring(0, 30),
          independent: [ocId._INDEPENDENT, ocId._OTHER].includes(
            values.company1,
          )
            ? 'Y'
            : 'N',
          agent_id: values.agent_id,
          role: values.agent_role,
          agency_name: company?.agency_name.substring(0, 30),
          region: values.region,
          ntid: values.ntid.substring(0, 15),
          lsp_cd: company?.lsp_cd?.trim(),
          market: company?.market?.trim(),
          territory: company?.territory_descr?.trim(),
          crd: company?.crd?.trim(),
          npn: company?.npn?.trim(),
        });
        residenceUpdate({
          email: contact?.email,
          address1: values.address,
          address2: values.address2,
          city: values.city,
          state: values.state,
          postal: values.zip,
          country: 'USA',
          phone: contact?.phone?.replace(/\D/g, ''),
          phone_type: contact?.residence_phone_type,
          primary_address: contact?.address_type_primary,
          primary_phone: contact?.phone_type_primary,
          primary_email: contact?.email_type_primary,
        });
      }
      // We want to register the course
      submitApplication(values, {
        onError: (res) => {
          isFormSubmitting(false);
          setError(res);
        },
        onSuccess: (data) => {
          if (data.student_id) {
            setStep('SUCCESS');
            gaEvents.eventGradAppSubmission(values.plan);
          } else {
            setError(`There was an error with the form submission.`);
          }
          isFormSubmitting(false);
        },
      });
    }
  };

  // Add a custom validation to check if input mmatches a specified value.
  Yup.addMethod(Yup.string, 'equals', function (value, errorMessage) {
    return this.test(`test-equals`, errorMessage, function (input) {
      const { path, createError } = this;
      return (
        (input && input === value) ||
        createError({ path, message: errorMessage })
      );
    });
  });

  // RegEx for phone number validation
  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const zipRegExp = /^\d{5}$/;
  let validation = {
    bachelors: Yup.string()
      .equals(
        'Y',
        "To be admitted into the program, you must have a bachelor's degree from an accredited college or university. If you have any questions, please contact an Admissions Advisor at 888-263-7265.",
      )
      .required('Required Field*'),
    address: Yup.string().required('Required Field*'),
    city: Yup.string().required('Required Field*'),
    state: Yup.string().required('Required Field*'),
    zip: Yup.string()
      .matches(zipRegExp, 'ZIP Code is not valid')
      .required('Required Field*'),
    phone: Yup.string()
      .matches(phoneRegExp, '*Invalid phone format')
      .required('Required Field*'),
    phoneType: Yup.string().required('Required Field*'),
    company1: Yup.string().required('Required Field*'),
    citizen: Yup.string().required('Required Field*'),
    language: Yup.string().required('Required Field*'),
    files: Yup.array().min(1, 'Atleast One Required'),
  };

  const initialValues = {
    bachelors: '',
    email: isReturning ? contact?.email : '',
    confirmEmail: '',
    firstName: isReturning ? student?.studentInfoInitial?.first_name : '',
    lastName: isReturning ? student?.studentInfoInitial?.last_name : '',
    prefix: isReturning ? student?.studentInfoInitial?.name_prefix?.trim() : '',
    address: isReturning ? contact?.address1 : '',
    address2: isReturning ? contact?.address2?.trim() : '',
    city: isReturning ? contact?.city : '',
    state: isReturning ? contact?.state : '',
    zip: isReturning ? contact?.postal : '',
    phone: isReturning ? contact?.phone?.replace(/\D/g, '') : '',
    phoneType: isReturning ? contact?.residence_phone_type : '',
    company1: isReturning ? company?.company_id : '',
    student_id: isReturning ? student?.studentInfoInitial?.empl_id : '',
    citizen: '',
    language: '',
    files: [],
    agent_id: isReturning ? company?.agent_id : '',
    agent_role: isReturning
      ? [cc._ALLSTATE, cc._STATEFARM].includes(company?.company_id)
        ? mapping.jobTitleReverse[company?.company_id][company.agent_role]
        : ''
      : '',
    region: isReturning ? company?.region : '',
    ntid: isReturning ? company?.nt_id : '',
  };

  if (designation === 'msfp') {
    initialValues.concentration = '';
    validation.concentration = Yup.string().required('Required Field*');
  }

  if (designation === 'msm') {
    initialValues.cohort = '';
    validation.cohort = Yup.string().required('Required Field*');
  }

  if (authState == null || !authState.isAuthenticated) {
    validation = {
      ...validation,
      ...{
        firstName: Yup.string().required('Required Field*'),
        lastName: Yup.string().required('Required Field*'),
        email: Yup.string().email('Invalid email').required('Required'),
        confirmEmail: Yup.string()
          .oneOf([Yup.ref('email'), null], "Emails don't match!")
          .required('Required Field*'),
      },
    };
  }

  const ValidationSchema = Yup.object().shape(validation);

  // We must return a loading screen to give the endpoint time
  // to pull back the student data for returning users.
  // We also want to show the timer if we sumbit the form.
  if (isReturning && (isLoading || contactLoading || companyLoading)) {
    return <Timer />;
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={false}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        formSubmit(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setErrors,
      }) => (
        <form onSubmit={handleSubmit} id="graduateApplicationForm">
          <div className="column-layout">
            <div className="col">
              <div className="field">
                <label className="radios-label" id="bachelors-radios">
                  Do you have a Bachelor's degree?*
                </label>
                <ul
                  className="radios"
                  role="group"
                  aria-labelledby="bachelors-radios"
                >
                  <li>
                    <Field
                      type="radio"
                      name="bachelors"
                      id="bachelors-yes"
                      value="Y"
                      checked={values.bachelors === 'Y'}
                    />
                    <label htmlFor="bachelors-yes">Yes</label>
                  </li>
                  <li>
                    <Field
                      type="radio"
                      name="bachelors"
                      value="N"
                      checked={values.bachelors === 'N'}
                      id="bachelors-no"
                    />
                    <label htmlFor="bachelors-no">No</label>
                  </li>
                </ul>
                {touched.bachelors && errors.bachelors && (
                  <span className="red-text">{errors.bachelors}</span>
                )}
              </div>
            </div>
          </div>
          <div>
            {designation === 'msm' && (
              <div className="column-layout">
                <div className="col">
                  <div className="field">
                    <FormikSelect
                      label="Please select which start date you're applying for:*"
                      id="cohort"
                      name="cohort"
                      value={values.cohort}
                      onChange={handleChange}
                    >
                      {cohortDates.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </FormikSelect>
                  </div>
                </div>
              </div>
            )}
            {designation === 'msfp' && (
              <div className="column-layout">
                <div className="col">
                  <div className="field">
                    <FormikSelect
                      label="Please select your concentration:*"
                      id="concentration"
                      name="concentration"
                      value={values.concentration}
                      onChange={handleChange}
                      className={
                        touched.concentration && errors.concentration
                          ? 'error'
                          : null
                      }
                    >
                      {concentration.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </FormikSelect>
                  </div>
                </div>
              </div>
            )}
          </div>

          {(authState == null || !authState.isAuthenticated) && (
            <>
              <label className="contact-label" id="contact-group">
                Personal Contact Information:
              </label>
              <div className="column-layout">
                <div className="col">
                  <div className="field">
                    <FormikText
                      type="email"
                      id="email"
                      name="email"
                      placeholder="* Email Address"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="field">
                    <FormikText
                      type="email"
                      id="confirmEmail"
                      name="confirmEmail"
                      placeholder="* Confirm Email Address"
                      value={values.confirmEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>
              <div className="column-layout">
                <div className="col--quarter">
                  <div className="field">
                    <FormikSelect
                      id="prefix"
                      name="prefix"
                      value={values.prefix}
                      onChange={handleChange}
                      className={
                        touched.prefix && errors.prefix ? 'error' : null
                      }
                    >
                      <option value="">Prefix</option>
                      <option value="Mr">Mr.</option>
                      <option value="Ms">Ms.</option>
                      <option value="Mrs">Mrs.</option>
                    </FormikSelect>
                  </div>
                </div>
                <div className="col--third">
                  <div className="field">
                    <FormikText
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="* First Name"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="col--third">
                  <div className="field">
                    <FormikText
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="* Last Name"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {authState != null && authState.isAuthenticated && (
            <label className="contact-label" id="contact-group">
              Please verify or update your personal contact information that we
              have on file:
            </label>
          )}
          <div className="column-layout">
            <div className="col">
              <div className="field">
                <FormikText
                  type="text"
                  id="address"
                  name="address"
                  placeholder="* Personal Street Address 1"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="col">
              <div className="field">
                <FormikText
                  type="text"
                  id="address2"
                  name="address2"
                  placeholder="Personal Street Address 2"
                  value={values.address2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </div>
          <div className="column-layout">
            <div className="col--third">
              <div className="field">
                <FormikText
                  type="text"
                  id="city"
                  name="city"
                  placeholder="* City"
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="col--third">
              <div className="field">
                <FormikSelect
                  id="state"
                  name="state"
                  value={values.state}
                  onChange={handleChange}
                >
                  {stateOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormikSelect>
              </div>
            </div>
            <div className="col--quarter">
              <div className="field">
                <FormikText
                  type="text"
                  id="zip"
                  name="zip"
                  placeholder="* Zip Code"
                  value={values.zip}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </div>
          <div className="column-layout">
            <div className="col">
              <div className="field">
                <FormikText
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="*Personal Phone Number"
                  value={values.phone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col">
              <div className="field">
                <FormikSelect
                  id="phoneType"
                  name="phoneType"
                  value={values.phoneType}
                  onChange={handleChange}
                  required
                >
                  {phoneOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </FormikSelect>
              </div>
            </div>
          </div>
          <CompanyAffiliationFormikField
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            companies={companies}
            companiesLoading={companiesLoading}
          />
          <CompanyCustomFormikFields
            values={values}
            handleChange={handleChange}
          />
          <div className="column-layout">
            <div className="col">
              <div className="field">
                <label className="radios-label" id="bachelors-radios">
                  Are you a U.S. Citizen?*
                </label>
                <ul
                  className="radios"
                  role="group"
                  aria-labelledby="citizen-radios"
                >
                  <li>
                    <Field
                      type="radio"
                      name="citizen"
                      id="citizen-yes"
                      value="Y"
                      checked={values.citizen === 'Y'}
                    />
                    <label htmlFor="citizen-yes">Yes</label>
                  </li>
                  <li>
                    <Field
                      type="radio"
                      name="citizen"
                      value="N"
                      checked={values.citizen === 'N'}
                      id="citizen-no"
                    />
                    <label htmlFor="citizen-no">No</label>
                  </li>
                </ul>
                {touched.citizen && errors.citizen && (
                  <span className="red-text">{errors.citizen}</span>
                )}
              </div>
            </div>
          </div>
          <div className="column-layout">
            <div className="col--third">
              <div className="field">
                <FormikText
                  type="text"
                  id="language"
                  name="language"
                  placeholder="* What is your primary language?"
                  value={values.language}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </div>
          <div className="info-section">
            <div>Upload your document:*</div>
            <div>
              <ul className="uploadDocuments">
                <li>Unofficial Undergraduate Transcript</li>
                <li>Your Resume </li>
              </ul>
            </div>

            <FileUpload
              selector="files"
              values={values.files}
              setFieldValue={setFieldValue}
              setErrors={setErrors}
              errors={touched.files && errors.files ? errors.files : ''}
              uploadCount={2}
            />
          </div>
          {values.bachelors === 'Y' ? (
            formSubmitting ? (
              <button
                type="submit"
                form="graduateApplicationForm"
                className="btn col disabled"
                disabled
              >
                Submitting...
              </button>
            ) : (
              <button
                type="submit"
                form="graduateApplicationForm"
                className="btn col"
              >
                Submit Application
              </button>
            )
          ) : (
            <button
              type="submit"
              form="graduateApplicationForm"
              className="btn col disabled"
              disabled
            >
              Submit Application
            </button>
          )}
          {error && <p className="red-text">{error}</p>}
        </form>
      )}
    </Formik>
  );
}
