import Accordion from '../subcomponents/Accordion';
import { Link } from 'react-router-dom';

export default function ReportingFAQs() {
  return (
    <div className="faqs">
      <div className="container">
        <h2>Continuing Education FAQs</h2>
        <Accordion
          title=<h4>
            Earning CE: What continuing education (CE) credits apply?
          </h4>
          id="cecredit_drawer"
        >
          <div className="p3">
            <ol className="upper-alpha">
              <li>
                Programs pre-approved for CE credits by the CFP Board of
                Standards, State Insurance Commission, State Bar or State Board
                of Accountancy are accepted by the Professional Recertification
                Program.
              </li>
              <li>
                Pass an examination in the recertification period for:
                <ol className="lower-alpha">
                  <li>
                    A course in The American College Huebner School or Graduate
                    School program or another recognized professional
                    designation program (30 hours per course); or
                  </li>
                  <li>A FINRA license (30 hours); or </li>
                  <li>The CFP® certification (30 hours); or </li>
                  <li>
                    A college or university course from which credit can be
                    earned toward a degree (15 hours for each semester credit
                    hour or its equivalent).
                  </li>
                </ol>
              </li>
              <li>
                Live/Virtual: Attend an educational program or meeting that has
                at least one (1) credit hour (50 minutes of attendance equals
                one credit hour).
              </li>
            </ol>
          </div>
        </Accordion>

        <Accordion
          title=<h4>Unacceptable activities for earning CE</h4>
          id="unacceptableactivities_drawer"
        >
          <div className="p3">
            <ul className="bullets">
              <li>
                Courses for state insurance licensing examination or examination
                (other than FINRA or CFP®) for a state or federal license
                related to financial services
              </li>
              <li>
                Personal development courses, such as motivation, public
                speaking, or salesmanship
              </li>
              <li>Product presentations</li>
              <li>Programs less than 50 minutes in length.</li>
            </ul>
          </div>
        </Accordion>

        <Accordion
          title=<h4>How are CE credit hours reported?</h4>
          id="cereporting_drawer"
        >
          <div className="p3">
            CE credits earned through coursework at the college will be recorded
            automatically. CE credits taken outside of the college are
            self-reported.
          </div>
        </Accordion>
        <Accordion
          title=<h4>
            Can I apply CE credits that I earned to meet other CE program
            requirements?
          </h4>
          id="applycecredits_drawer"
        >
          <div className="p3">
            Credits approved for state CE, CFP®, CPE, CLE, and securities
            professionals that are accrued in the professional recertification
            reporting cycle can be applied to professional recertification
            program requirements. Credits earned outside the reporting cycle do
            not apply.
          </div>
        </Accordion>
        <Accordion
          title=<h4>
            How will my CE requirements be affected if my client-facing status
            changes in the middle of a reporting cycle?
          </h4>
          id="clientfacingstatuschange_drawer"
        >
          <div className="p3">
            Should the client-facing status of a designee change mid-cycle,
            their CE requirements are still based on their status at the
            beginning of the current two-year cycle and will change at the
            beginning of the next cycle. The annual recertification fee will
            change to reflect current client-facing status at the next annual
            payment due date.
          </div>
        </Accordion>
        <Accordion
          title=<h4>Professional Recertification Program Guidelines</h4>
          id="recertguidelines_drawer"
        >
          <div className="p3">
            For more detailed information on requirements, review our{' '}
            <Link
              to={{
                pathname: `${process.env.REACT_APP_MARKETING_URL}sites/default/files/2023-09/recertification-program-guidelines-2024.pdf`,
              }}
              className="standard-link"
              target="_blank"
            >
              Professional Recertification Program Guidelines
            </Link>
            .
          </div>
        </Accordion>
      </div>
    </div>
  );
}
