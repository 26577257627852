import React, { useState, useEffect } from 'react';
import { Route, Switch, useLocation, matchPath } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import { Footer } from '../components/global/Footer';
import { Header } from '../components/global/Header';
import { VpnWarningBanner } from '../components/subcomponents/VpnWarningBanner';

import { useOktaAuth } from '@okta/okta-react';
import ReadStorage from '../components/storage/ReadStorage';
import SetStorage from '../components/storage/SetStorage';

import useTacfsService from '../utils/tacfs/useTacfsService';

import {
  TacfsContext,
  PortalMessageContext,
} from '../components/global/Context';
import PrivateRoute from './PrivateRoute';

import routesList from './routesList';

export function Routes() {
  const [tacfsContext, setContext] = useState({});
  const [studentIdEnabled, setStudentIdEnabled] = useState(false);
  const [isMasquerading, setIsMasquerading] = useState(false);
  const [messageContext, setMessageContext] = useState({});
  const { authState } = useOktaAuth();

  const { load, save } = useTacfsService();
  const location = useLocation();

  // Load the student data from the API
  // We want to check the session every 5min to ensure user is still logged  in.
  const { isFetching: userDataLoading, data: userData } = useQuery(
    ['userData'],
    () => load('getUserData', { authState: authState }),
    {
      enabled: authState != null && authState.isAuthenticated,
      staleTime: 5 * (60 * 1000), // 5 mins
    },
  );

  const { isLoading, data: student } = useQuery(
    ['studentInitial'],
    () => load('studentInitial', { studentID: userData.profile.studentID }),
    {
      enabled: userDataLoading === false && studentIdEnabled,
    },
  );
  const { isLoading: flagsAreLoading, data: studentFlags } = useQuery(
    ['studentFlags'],
    () => load('studentFlags', { studentID: userData.profile.studentID }),
    {
      enabled: userDataLoading === false && studentIdEnabled,
    },
  );
  const { mutate: unifyAlumniStatus } = useMutation((values) =>
    save('unifyAlumniStatus', values),
  );

  // Make sure the user is not masquerading.
  useEffect(() => {
    if (
      flagsAreLoading === false &&
      studentFlags &&
      authState?.accessToken?.claims?.uid &&
      userData?.isMasquerading !== true
    ) {
      const data = {
        isAlumni: studentFlags.imodules_access,
      };
      // Check if this is a masquerading user.
      if (ReadStorage('masqueradingUser') !== undefined) {
        data.uid = ReadStorage('masqueradingUser');
      }
      // Run the alumni call.
      unifyAlumniStatus(data);
    }
  }, [
    flagsAreLoading,
    studentFlags,
    userData,
    unifyAlumniStatus,
    authState?.accessToken?.claims?.uid,
  ]);

  // Paths where we want to hide the header and breadcrumbs
  const hiddenHeaderPath = matchPath(location.pathname, {
    path: [
      '/',
      '/auth/login',
      '/auth/mfa',
      '/auth/ssonwm/login',
      '/account/nwm-setup',
      '/auth/sso/admin-login/:uid',
      '/auth/sso/login',
      '/account/setup/:webToken',
      '/account/password-reset',
    ],
    exact: true,
    strict: false,
  });

  useEffect(() => {
    if (isLoading === false && student) {
      if (Object.keys(tacfsContext).length === 0) {
        setContext(student);
      }
    }
  }, [student, isLoading, tacfsContext]);

  useEffect(() => {
    if (userDataLoading === false && userData) {
      if (ReadStorage('oktaProfile') === undefined) {
        SetStorage('oktaProfile', userData);
      }
      if (userData?.isMasquerading) {
        setIsMasquerading(userData.id);
      }
      if (userData?.profile?.studentID) {
        setStudentIdEnabled(true);
      }
    }
  }, [userDataLoading, userData]);

  return (
    <TacfsContext.Provider
      value={{
        tacfsContext: tacfsContext,
        setContext: (value) => {
          setContext(value);
        },
      }}
    >
      <VpnWarningBanner />
      <PortalMessageContext.Provider
        value={{ messageContext, setMessageContext }}
      >
        {!hiddenHeaderPath && <Header isMasquerading={isMasquerading} />}
      </PortalMessageContext.Provider>
      <PortalMessageContext.Provider
        value={{ messageContext, setMessageContext }}
      >
        <Switch>
          {routesList.map((route) => {
            if (route.private) {
              return (
                <PrivateRoute
                  exact
                  path={route.path}
                  component={route.component}
                  key={route.path}
                />
              );
            } else {
              return (
                <Route
                  exact
                  path={route.path}
                  component={route.component}
                  key={route.path}
                />
              );
            }
          })}
        </Switch>
      </PortalMessageContext.Provider>
      <Footer />
    </TacfsContext.Provider>
  );
}
