import { useState, useEffect, useCallback } from 'react';

import { useOktaAuth } from '@okta/okta-react';
import { useQuery, useMutation } from 'react-query';
import useTacfsService from '../utils/tacfs/useTacfsService';
import ReadStorage from '../components/storage/ReadStorage';

import { Layout } from '../components/Layout';
import { BasicCreateAccountForm } from '../components/createAccount/BasicCreateAccountForm';
import { Link } from 'react-router-dom';

import { OktaSSOLink } from '../_okta/config/OktaSSO';
import SetStorage from '../components/storage/SetStorage';
import { ReflexBlueLogoImage } from '../components/subcomponents/Icon';
import nMlogo from '../assets/nm_logo_horz_blue.png';

import Timer from '../components/subcomponents/Timer';
import { FormikCheckbox } from '../components/subcomponents/InputField';
import { Formik } from 'formik';
import * as Yup from 'yup';

export function CreateSSOAccount() {
  const { authState, oktaAuth } = useOktaAuth();
  const [completed, setCompleted] = useState(false);
  const { load, save } = useTacfsService();
  const [status, setStatus] = useState('');
  const [desc, setDesc] = useState('');
  const [error, setError] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);

  // Load the student data from the API
  // We want to check the session every 5min to ensure user is still logged  in.
  const {
    isFetching: userDataLoading,
    data: userData,
    refetch,
  } = useQuery(
    ['userData'],
    () => load('getUserData', { authState: authState }),
    {
      enabled: authState != null,
      staleTime: 5 * (60 * 1000),
    },
  );

  const { isLoading: submitNWMVerify, mutate: nwmVerify } = useMutation(
    (values) => save('verifyNwm', values),
  );

  const updateProfile = useCallback(
    async (updateToken) => {
      // Refect the data
      // to get the student ID.
      refetch();
      // if we need to update the AccessToken
      // we must do it here so we can retrieve the
      // update jwt token with the student ID in
      // the claims.
      if (updateToken) {
        console.log('in');
        await oktaAuth?.session
          ?.refresh()
          .then(function (session) {
            // existing session is now refreshed
            SetStorage('session', session.id);
            // Set where we to be directed to after updating
            // the access token.
            SetStorage('loginDestination', '/account/nwm-setup');
            // we want to set the new token ID so we need to
            // redirect with the session.
            oktaAuth.token.getWithRedirect({
              sessionToken: session.id,
              responseType: ['code', 'email', 'profile'],
              state: 'null',
            });
          })
          .catch(function (err) {
            console.error(err);
          });
      }
      // Set the completer flag.
      setCompleted(true);
    },
    // @TODO: Check if needed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetch],
  );

  useEffect(() => {
    if (userDataLoading === false && !userData?.profile?.nwmUuid) {
      // Dont allow user to see this page
      // if they dont have a nwm UUID on file
      // in Okta.
      setAccessDenied(true);
    }
  }, [userData, userDataLoading]);

  useEffect(() => {
    if (
      userDataLoading === false &&
      userData?.profile?.studentID &&
      completed
    ) {
      // we want to update the storage profile
      // after we update okta with the student ID.
      SetStorage('oktaProfile', userData);
    }
  }, [userDataLoading, userData, completed]);

  useEffect(() => {
    if (
      userData &&
      submitNWMVerify === false &&
      error === false &&
      status === ''
    ) {
      const data = {
        uuid: userData.profile.nwmUuid,
        firstName: userData.profile.firstName,
        middleName: userData.profile.middleName,
        lastName: userData.profile.lastName,
        login: userData.profile.login,
        email: userData.profile.email,
        studentID: userData?.profile?.studentID,
      };
      // We want to register the course
      nwmVerify(data, {
        onError: (res) => setError(res),
        onSuccess: (data) => {
          if (data.tac_nm_status) setStatus(data.tac_nm_status);
          if (data.descr) setDesc(data.descr);
          // Refect the data
          // to get the student ID.
          updateProfile(false);
        },
      });
    }
  }, [submitNWMVerify, userData, nwmVerify, error, status, updateProfile]);

  const redirectToEnrollment = () => {
    const sessionToken = ReadStorage('session');
    window.location.href = `${process.env.REACT_APP_ECOMM_URL}utilAuth/loginSSO?session=${sessionToken}&params=nwm=1`;
  };

  if (accessDenied) {
    return (
      <Layout>
        <div className="container password-reset-page">
          <div className="logo">
            <div className="logo-container">
              <ReflexBlueLogoImage className="h-24" />
            </div>
            <div className="logo-container">
              <img
                src={nMlogo}
                className="h-24 nm-logo"
                alt="Northwestern Mutual logo"
              />
            </div>
          </div>
          <div>
            <h2>Access Denied!</h2>
          </div>
        </div>
      </Layout>
    );
  }

  if (status && userData?.profile?.studentID) {
    return (
      <Layout>
        <div className="container password-reset-page">
          <div className="logo">
            <div className="logo-container">
              <ReflexBlueLogoImage className="h-24" />
            </div>
            <div className="logo-container">
              <img
                src={nMlogo}
                className="h-24 nm-logo"
                alt="Northwestern Mutual logo"
              />
            </div>
          </div>
          <div className="column-layout container">
            <div>
              <h3>Hi {userData.profile.firstName}, Let's Get Started!</h3>
              <div>
                <p>{desc}</p>
                {status.toLowerCase() === 'e' && (
                  <>
                    <h5>ELIGIBILITY</h5>
                    <strong>*** EFFECTIVE JANUARY 1, 2024 ***</strong>
                    <p>
                      Northwestern Mutual continues to have the most
                      professional and highly trained financial representatives
                      and team members and we know this contributes to our
                      unparalleled client outcomes. Annually, we commit more
                      than $3 million to our Industry Designation Program so you
                      are equipped with the education you need to stay ahead in
                      the industry, to meet the highest standard of excellence
                      and to provide the service your clients need.
                    </p>
                    <p>
                      To ensure resources dedicated to the program reach more
                      individuals at the right time in their careers at NM, we
                      are introducing the following policies, effective January
                      1, 2024:
                    </p>
                    <ul className="bullets">
                      <li>
                        Individuals are required to have a minimum of three
                        years of service at NM to qualify for the designation
                        pre-payment and reimbursement program. A college
                        internship contract at NM counts toward the length of
                        service requirement.
                      </li>
                      <li>
                        Financial representatives/advisors/field leaders must
                        have at least one designation in order for their
                        AFR/advisors, financial representatives' assistants and
                        network office staff to begin earning a designation.
                        This change helps ensure the leader is equipped to guide
                        and mentor those working toward a designation.
                      </li>
                      <li>
                        Financial support will be limited to a maximum of three
                        designations per individual, earned while associated
                        with NM. Those wishing to pursue additional designations
                        awarded through The American College will receive NM's
                        discounted rate.
                      </li>
                    </ul>
                    <p>
                      All students enrolled in a designation before January 1,
                      2024 will continue to have that designation prepaid by NM.
                    </p>
                    <p>
                      These policies continue to uphold our commitment to
                      maintaining the highest standard of industry excellence.
                      If you have questions regarding these updates, please
                      contact your field leader or the professional growth
                      mentor in your office.
                    </p>
                    <Formik
                      initialValues={{ attestation: false }}
                      validationSchema={validationSchema}
                      onSubmit={redirectToEnrollment}
                    >
                      {({ values, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit} id="attestationForm">
                          <div>
                            <ul className="checkboxes">
                              <li>
                                <FormikCheckbox
                                  name="attestation"
                                  id="attestation"
                                  checked={values.attestation}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <strong>
                                    I ATTEST THAT I MEET THESE POLICIES OR HAVE
                                    AN APPROVED EXCEPTION
                                  </strong>
                                </FormikCheckbox>
                              </li>
                            </ul>
                            <br />
                            <button
                              type="submit"
                              form="attestationForm"
                              className="btn"
                            >
                              Continue to enrollment
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </>
                )}
                {status.toLowerCase() !== 'e' &&
                  status.toLowerCase() !== 'n' && (
                    <>
                      <div>
                        <p>
                          You are not approved for enrollment. If you proceed,
                          you will need to pay with a credit card. If you have
                          any questions, please contact{' '}
                          <a href="mailto:designations@northwesternmutual.com">
                            designations@northwesternmutual.com
                          </a>
                          .
                        </p>
                      </div>
                      <OktaSSOLink classes="btn" sameTab>
                        Continue to enrollment
                      </OktaSSOLink>
                    </>
                  )}
              </div>
              <br />
              <div className="login-desc">
                <p>Access education and insights including:</p>
                <ul className="bullets">
                  <li>Your student profile and learning journey</li>
                  <li>Course history and transcripts</li>
                  <li>Recertification and CE reporting</li>
                  <li>Messages tailored to your status and preferences</li>
                  <li>A library of valuable resources</li>
                </ul>
              </div>
              <Link to="/dashboard" className="btn">
                Click here to go to your student portal at The American College
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  if (
    userDataLoading === false &&
    submitNWMVerify === false &&
    !userData?.profile?.studentID
  ) {
    return (
      <>
        <Layout>
          <div className="container password-reset-page">
            <div className="logo">
              <div className="logo-container">
                <ReflexBlueLogoImage className="h-24" />
              </div>
              <div className="logo-container">
                <img
                  src={nMlogo}
                  className="h-24 nm-logo"
                  alt="Northwestern Mutual logo"
                />
              </div>
            </div>
            <h2>Account Creation</h2>
            <h4>
              Welcome to The American College of Financial Services. Please
              follow the steps to setup your account.
            </h4>
            <div className="form-layout">
              <BasicCreateAccountForm
                setCompleted={() => updateProfile(true)}
                userData={userData}
                company={'1000277'}
                showDubModal={false}
              />
            </div>
          </div>
        </Layout>
      </>
    );
  }

  if (error) {
    return (
      <Layout>
        <div className="container password-reset-page">
          <div className="logo">
            <div className="logo-container">
              <ReflexBlueLogoImage className="h-24" />
            </div>
            <div className="logo-container">
              <img
                src={nMlogo}
                className="h-24 nm-logo"
                alt="Northwestern Mutual logo"
              />
            </div>
          </div>
          <div>
            <h2>Uh oh! Something went wrong.</h2>
          </div>
        </div>
      </Layout>
    );
  }

  return <Timer />;
}

const validationSchema = Yup.object().shape({
  attestation: Yup.boolean().oneOf(
    [true],
    'You must agree to the attestation.',
  ),
});
