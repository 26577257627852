import { useState } from 'react';

export function ResourceFilters({
  reset,
  addFilterParam,
  searchFields,
  setSearchFields,
  searchFunction,
  isSavedResources = false,
  title = 'Resources',
}) {
  const [filterToggle, setfilterToggle] = useState(0);

  const handleChange = (event) => {
    setSearchFields({
      ...searchFields,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    searchFunction();
  };

  const contentFormatTermNames = [
    'Insights',
    'News',
    'On-Demand Webcast',
    'Podcast',
    'Press',
    'Research',
  ];
  return (
    <div className="resourceFilters">
      <form onSubmit={handleSubmit}>
        <div className="searchFields">
          <div className="inline-field search">
            <label htmlFor="textSearch">Search Title</label>
            <input
              type="text"
              placeholder="Enter Search"
              name="textSearch"
              value={searchFields.textSearch}
              onChange={handleChange}
            />
          </div>
          <div className="inline-field select">
            <label htmlFor="format">Select Formats</label>
            <select
              name="format"
              value={searchFields.format}
              onChange={handleChange}
            >
              <option key="0" value="" disabled hidden>
                Select Option
              </option>
              {contentFormatTermNames.map((termName, i) => {
                return (
                  <option key={i} value={termName}>
                    {termName}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="searchButtons">
            <button type="submit" className="btn">
              Search
            </button>
            <button
              className="underline-link"
              onClick={() => {
                setfilterToggle(0);
                reset();
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </form>
      <div className="title-row flex">
        <div className="title-wrapper">{title}</div>
        <div className="filterOptions">
          <button
            className={`btn secondary ${filterToggle === 1 ? 'selected' : ''}`}
            onClick={() => {
              addFilterParam({ filterBy: 'new' });
              setfilterToggle(1);
            }}
          >
            View By New
          </button>
          {!isSavedResources ? (
            <>
              <button
                className={`btn secondary ${
                  filterToggle === 2 ? 'selected' : ''
                }`}
                onClick={() => {
                  addFilterParam({ filterBy: 'trending' });
                  setfilterToggle(2);
                }}
              >
                View By Trending
              </button>
              <button
                className={`btn secondary ${
                  filterToggle === 3 ? 'selected' : ''
                }`}
                onClick={() => {
                  addFilterParam({ filterBy: 'popular' });
                  setfilterToggle(3);
                }}
              >
                View By Popular
              </button>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}
