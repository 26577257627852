import { useEffect, useState } from 'react';
import browserUpdate from 'browser-update';
/**
 * Displays a warning banner if the browser is older than 2 versions from the current.
 * @returns {undefined}
 */
export function BrowserWarningBanner() {
  const [showBrowserWarning, setShowBrowserWarning] = useState(false);
  const warningCopy =
    'ATTENTION: Please update your browser or use a personal device to continue browsing our site.';
  useEffect(() => {
    browserUpdate({
      onshow: () => {
        setShowBrowserWarning(true);
      },
      required: { e: -2, f: -2, o: -2, s: -2, c: -2 },
      insecure: true,
      unsupported: true,
      test: false,
      debug: false,
      nomessage: true,
    });
    // @TODO: Check this dependency array. something seems strange.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browserUpdate, setShowBrowserWarning]);

  return !showBrowserWarning ? (
    <></>
  ) : (
    <div className="notification browserWarning">
      <div className="container--lg notif-wrapper">
        <p className="eyebrow"></p>
        <p className="desktop">{warningCopy}</p>{' '}
        <p className="mobile">{warningCopy}</p>{' '}
      </div>
    </div>
  );
}
