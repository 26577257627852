import { useState } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import { useMutation } from 'react-query';

import useTacfsService from '../../utils/tacfs/useTacfsService';
import Timer from '../subcomponents/Timer';

export function SupervisorApprovalForm(props) {
  // Load the tacfs helper Service Worker.
  const { save } = useTacfsService();
  const [acknowledgement, setAcknowledgement] = useState('');
  const [error, setError] = useState('');
  const [sent, setSent] = useState(false);
  const { isLoading: supervisorApprovalLoading, mutate: supervisorApproval } =
    useMutation((values) => save('designationAppSupervisor', values));

  const formSubmit = async (values) => {
    // we want to add the response to
    // the return json.
    values.studentID = props.studentId;
    values.applId = props.applId;
    values.designation = props.designation;
    values.response = acknowledgement;
    // We want to register the course
    supervisorApproval(values, {
      onError: (res) => setError(res),
      onSuccess: (data) => {
        if (data.error === 'S') setSent(true);
        else setError(`There was an error with the form submission.`);
      },
    });
  };

  if (supervisorApprovalLoading) return <Timer />;

  // Show the confirmation message when submission is sent.
  if (sent) return <h2>Thank you for your submission!</h2>;

  return (
    <>
      <h2>Supervisor Confirmation Form</h2>
      {error && <p className="red-text">{error}</p>}
      <Formik
        initialValues={{
          fullName: '',
          title: '',
          organization: '',
          phone: '',
          aggreement: false,
        }}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          formSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} id="supervisorApprovalForm">
            <div className="field">
              <Field
                type="text"
                id="fullName"
                name="fullName"
                value={values.fullName}
                className={touched.fullName && errors.fullName ? 'error' : null}
                onChange={handleChange}
                placeholder="* Supervisor Full Name"
                onBlur={handleBlur}
              />
              {touched.fullName && errors.fullName && (
                <span className="red-text">{errors.fullName}</span>
              )}
            </div>
            <div className="field">
              <Field
                type="text"
                id="title"
                name="title"
                value={values.title}
                className={touched.title && errors.title ? 'error' : null}
                onChange={handleChange}
                placeholder="* Your Title"
                onBlur={handleBlur}
              />
              {touched.title && errors.title && (
                <span className="red-text">{errors.title}</span>
              )}
            </div>
            <div className="field">
              <Field
                type="text"
                id="organization"
                name="organization"
                value={values.organization}
                className={
                  touched.organization && errors.organization ? 'error' : null
                }
                onChange={handleChange}
                placeholder="* Name of Organization"
                onBlur={handleBlur}
              />
              {touched.organization && errors.organization && (
                <span className="red-text">{errors.organization}</span>
              )}
            </div>
            <div className="field">
              <Field
                type="text"
                id="phone"
                name="phone"
                value={values.phone}
                className={touched.phone && errors.phone ? 'error' : null}
                onChange={handleChange}
                placeholder="* Your Phone Number"
                onBlur={handleBlur}
              />
              {touched.phone && errors.phone && (
                <span className="red-text">{errors.phone}</span>
              )}
            </div>

            <div className="field">
              <ul class="checkboxes">
                <li>
                  <input
                    type="checkbox"
                    id="aggreement"
                    name="aggreement"
                    className={
                      touched.aggreement && errors.aggreement ? 'error' : null
                    }
                    checked={values.aggreement}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label htmlFor="aggreement">
                    <p>
                      I reviewed {props.fullName}'s statements on the
                      application for the time spent with our organization.
                    </p>
                    <p>
                      If I approve the application, I regard {props.fullName}'s
                      business methods and ethical conduct as being satisfactory
                      in every particular and have no reservations recommending
                      them for an American College designation.
                    </p>
                  </label>
                  {touched.aggreement && errors.aggreement && (
                    <span className="red-text">{errors.aggreement}</span>
                  )}
                </li>
              </ul>
            </div>

            <div className="flex w-full column-layout">
              <button
                type="submit"
                form="supervisorApprovalForm"
                onClick={() => setAcknowledgement('A')}
                className="btn col"
              >
                I confirm this work experience
              </button>
              <button
                type="submit"
                form="supervisorApprovalForm"
                onClick={() => setAcknowledgement('D')}
                className="btn col"
              >
                I deny this work experience
              </button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

// RegEx for phone number validation
const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const ValidationSchema = Yup.object().shape({
  fullName: Yup.string().max(50, 'Too Long').required('Required Field*'),
  title: Yup.string().max(80, 'Too Long').required('Required Field*'),
  organization: Yup.string().max(50, 'Too Long').required('Required Field*'),
  phone: Yup.string()
    .matches(phoneRegExp, '*Phone number is not valid')
    .required('*Phone number required'),
  aggreement: Yup.boolean().oneOf([true], 'You must agree'),
});
